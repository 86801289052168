import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { Modal, DatePicker } from "@vacasa/react-components-lib";
import { LoaderButton} from "components/loader-button/LoaderButton";
import { ThunkDispatch } from "@reduxjs/toolkit";
import styles from "./TicketEditDueDateModal.module.scss";
import {fetchTimes} from "store/ticketEditTimesSlice";
import {updateDueDate} from "store/ticketEditSlice";
import Alert from "@mui/material/Alert";
import classNames from "classnames";
import moment from 'moment-timezone';
import {fetchHistory} from "../../store/ticketEditHistorySlice";
import {isNil} from "lodash";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import TicketingService from "services/TicketingService";

interface TicketEditDueDateModalProps {
    ticketId: number;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const TicketEditDueDateModal: React.FC<TicketEditDueDateModalProps> = (
    {ticketId, setIsModalOpen}
) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const currentUserId = TicketingService.getInstance().currentUserId;
    const dueDate = useSelector((state: RootState) => state.ticketEdit.ticket?.completion_date);
    const creationDate = useSelector((state: RootState) => state.ticketEdit.ticket?.creation_date);

    // internal states
    const formatDate: string = "MM/DD/YYYY";
    const getDateString = (value: moment.Moment) => moment(value).local(true).toISOString();
    const minDate: moment.Moment = isNil(creationDate) ? moment() : moment(creationDate);
    const initialDate: moment.Moment = isNil(dueDate) ? moment() : moment(dueDate);
    const [dateInternal, setDateInternal] = useState<moment.Moment | null>(initialDate);
    const [dateFormatted, setDateFormatted] = useState<string>(getDateString(initialDate));
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

    // validations
    const isDateInPast: boolean = isNil(dateFormatted) ? false : moment(dateFormatted).isBefore(moment(), "days");

    // handlers
    const handleUpdate = async () => {
        await dispatch(updateDueDate({ticketId, oldDueDate: dueDate, newDueDate: dateFormatted}))
        setIsModalOpen(false);
        dispatch(fetchHistory(ticketId));
        dispatch(fetchTimes({ticketId, userId: currentUserId}));
    }

    const handleChange = async (value: MaterialUiPickersDate | null): Promise<void> => {
        if (isNil(value)) {
            setDateInternal(null);
            setDateFormatted("");
        } else {
            setDateInternal(moment(value));
            setDateFormatted(getDateString(value));
        }
        setDatePickerOpen(false);
    }

    return (
        <Modal showModal={true} canExit={true} setShowModal={setIsModalOpen} size="medium">
            <div className={styles.modal}>
                <h4 data-testid="due-date-modal-title" className="mb20">Edit Due Date</h4>

                { /* Date in past warning */}
                { isDateInPast && (
                    <div data-testid="date-in-past-warning">
                        <Alert severity="warning">
                            Warning: This date is in the past.
                        </Alert>
                    </div>
                )}

                <p data-testid="due-date-modal-instructions" className="mb15">Select a date in the calendar to edit the due date of this ticket.</p>

                {/* Select Date */}
                <div data-testid="due-date-modal-datepicker" className={styles["date-selector"]}>
                    <DatePicker
                        open={datePickerOpen}
                        label={`Date (${formatDate})`}
                        placeholder="Due Date"
                        minDate={minDate.format(formatDate)}
                        value={dateInternal}
                        onChange={handleChange}
                        onClick={() => setDatePickerOpen(true)}
                        onOpen={() => setDatePickerOpen(true)}
                        onClose={() => setDatePickerOpen(false)}
                        format={"MM/dd/yyyy"}
                        variant="inline"
                        disableToolbar={true}
                    />
                </div>

                {/*Due Date actions*/}
                <div className={styles["due-date-actions-box"]}>
                    <button
                        data-testid="due-date-modal-cancel-button"
                        className={classNames("button button-primary", styles["cancel-btn"])}
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        id={"due-date-modal-update-button"}
                        variant="primary-inverse"
                        onClick={handleUpdate}
                        className={styles["update-due-date-btn"]}
                    >
                        Save
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
