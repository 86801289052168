import React from "react";
import {SearchBar, ISearchBarProps, ItemType} from "components/search-bar/SearchBar";
import {AssignableUserAttributes} from "typing/dto";
import {TicketingService} from "services/TicketingService";
import {DataType} from "typing/request";
import {isNil} from "lodash";
import { enqueueError, getErrorMessage } from "utils/message";
import { EVENT_LOG_DATA } from "appConstants";

interface ISearchAssignableUsersBarProps extends Pick<ISearchBarProps<UserItemType>, "onSelected" | "disabled"> {
    disabledUsers?: Array<{id: number}>;
}

export interface UserItemType extends ItemType {
    firstName?: string;
    lastName?: string;
    title?: string;
}

// services
const ticketingService = TicketingService.getInstance();

export const SearchAssignableUsersBar: React.FC<ISearchAssignableUsersBarProps> = (
    {onSelected, disabled, disabledUsers}
) => {

    // Method to fetch assignable users and set in options to auto complete component
    const fetchAssignableUsers = async (search: string) : Promise<UserItemType[]> => {
        try {
            const response = await ticketingService.getAssignees({
                search_term: search
            });
            return response?.data.map((user: DataType<AssignableUserAttributes>): UserItemType => ({
                id: Number(user.id),
                firstName: user.attributes.first_name,
                lastName: user.attributes.last_name,
                title: user.attributes.title,
                display: `${user.attributes.first_name} ${user.attributes.last_name} (${user.attributes.title})`
            }));
        } catch (error) {
            enqueueError({logInfo: EVENT_LOG_DATA.TICKET_ASSIGNABLE_USER_GET, error: Error(getErrorMessage(error))});
            return [];
        }
    };

    return (
        <SearchBar
            fetchData={fetchAssignableUsers}
            onSelected={onSelected}
            getOptionLabel={(option: UserItemType) => option.display}
            getOptionSelected={(option: UserItemType, value: UserItemType): boolean => option.id === value.id}
            getOptionDisabled={(option: UserItemType): boolean =>
                !isNil(disabledUsers) && disabledUsers.some((disabled: {id: number}): boolean => disabled.id === option.id)}
            disabled={disabled}
        />
    )
}
