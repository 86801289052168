import React, { useState } from "react";
import { Modal } from '@vacasa/react-components-lib';
import { LoaderButton } from "components/loader-button/LoaderButton";
import { fetchHistory } from "store/ticketEditHistorySlice";
import { useDispatch } from "react-redux";
import { updateSeverity } from "store/ticketEditSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { TicketEntity } from "entities/ticket";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import styles from "./TicketEditSeverityModal.module.scss";

interface ITicketEditSeverityModalProps {
    ticketId: number;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    currentSeverityId: number;
}

type SeverityType = {
    name: string;
    id: number;
}

export const TicketEditSeverityModal: React.FC<ITicketEditSeverityModalProps> = ({ ticketId, setShowModal, currentSeverityId}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [severity, setSeverity] = useState<SeverityType | null>(null);

    const handleSubmit = async () => {
        if (!severity) return

        await dispatch(updateSeverity({ticketId, severityId: severity.id}))
        setShowModal(false)
        dispatch(fetchHistory(ticketId));
    }

    const handleSelectedSeverity = (_event: any, newValue: SeverityType | null) => {
        setSeverity(newValue);
    }

    return (
        <Modal showModal setShowModal={() => setShowModal(false)} size="large">
            <div className="modal">
                <h1 className={styles["modal-title"]}>Edit Severity</h1>
                <div>
                    <label className={styles["severity-label"]} htmlFor="severity-options">
                        <p><b>SEVERITY*</b></p>
                    </label>
                    <Autocomplete
                        id="severity-options"
                        options={TicketEntity.severityOptions}
                        value={severity}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionDisabled={(option) => option.id === currentSeverityId}
                        onChange={handleSelectedSeverity}
                        renderInput={(params) => <TextField {...params} label="Select an option" variant="outlined" />}
                    />
                </div>
                <div className="modal-actions">
                    <button
                        className="button button-primary"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        variant="primary-inverse"
                        onClick={handleSubmit}
                        disabled={!severity}
                    >
                        Save
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
