import React, { ChangeEvent, useState } from "react";
import { useAppDispatch } from "store/store";
import { Modal } from '@vacasa/react-components-lib';
import { truncateFilename } from "utils/helpers";
import { addAttachments } from "store/ticketEditAttachmentsSlice";
import { LoaderButton } from "components/loader-button/LoaderButton";
import styles from "./UploadAttachmentModal.module.scss";

interface UploadAttachmentModalProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    ticketId: number;
}

const getFileList = (files: any) => {
    if (!files) return

    return (
        <ul>
            {Object.values(files).map((file: any, index: number) => (<li key={index}>{truncateFilename(file.name, 30)}</li>))}
        </ul>
    );
}


export const UploadAttachmentModal: React.FC<UploadAttachmentModalProps> = ({ setShowModal, ticketId }) => {
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const dispatch = useAppDispatch();

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles(event.target.files);
    }

    const handleUpload = async () => {
        if (!selectedFiles) return

        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i]);
        }

        await dispatch(addAttachments({ticketId, formData}));
        setShowModal(false);
    }

    return (
        <Modal
            showModal={true}
            setShowModal={() => setShowModal(false)}
            size="large"
        >
            <div className="modal">
                 <h1>Attach a file(s)</h1>
                <div>
                    <form id={styles.attachmentsForm}>
                        <input type="file" id="upload-btn" multiple onChange={handleFileChange} />
                        <label htmlFor="upload-btn">Choose Files</label>
                    </form>
                    <div className={styles.uploadAttachmentsList}>
                        {getFileList(selectedFiles) || <span>No file chosen</span>}
                    </div>
                </div>
                <div className="modal-actions">
                    <button
                        className="button button-primary"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        variant="primary-inverse"
                        onClick={handleUpload}
                        disabled={!selectedFiles}
                    >
                        Attach
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
