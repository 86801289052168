import {datadogLogs} from "@datadog/browser-logs";
import {GenericSnackbarMessage} from "typing/message";
import {getSnackbarMessage} from "utils/message";

interface DispatchType {
    title: string;
    message: string;
    type: string;
    source: string;
    level: string;
    data?: any;
}

/**
 * Logger on top of datadogLogger that will add some extra tags
 * to improve Log grouping in datadog
 */
export class EventLogger {
    private static SOURCE: string = "UI";
    public static ERROR_LEVEL: string = "error";
    public static INFO_LEVEL: string = "info";
    public static WARNING_LEVEL: string = "warn";
    public static SCOPE: string = "event";

    /**
     *
     * @param datadogLogs datadogLog instance
     */
    public static emit({title, message, type, source, level, data = {}}: DispatchType) {
        // @ts-ignore
        datadogLogs.logger[level](title, {
            scope: EventLogger.SCOPE,
            message: `[${source}] ${message}`,
            event: {type, source},
            level,
            data,
        });
    }

    /**
     * Emit a generic event from EventLogInfo
     */
    public static emitSuccessEvent({logInfo, data}: GenericSnackbarMessage) {
        const {target = "ticket", action = "updated", logEvent = "TICKET_APP_RENDER"} = logInfo;
        EventLogger.emit({
            title: `${target[0].toUpperCase() + target.slice(1)} ${action[0].toUpperCase() + action.slice(1)}`,
            message: getSnackbarMessage({logInfo}),
            type: logEvent,
            source: EventLogger.SOURCE,
            level: EventLogger.INFO_LEVEL,
            data,
        });
    }
}
