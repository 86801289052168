import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EVENT_LOG_DATA} from "appConstants";
import {TicketingService} from "services/TicketingService";
import {TicketReminderV1Attributes} from "typing/dto";
import {enqueueError, enqueueSuccess, getErrorMessage} from "utils/message";

const {TICKET_REMINDER_DELETE, TICKET_REMINDER_ADD, TICKET_REMINDER_GET} = EVENT_LOG_DATA;

// Service Singletons
const ticketingService = TicketingService.getInstance();

interface IRemindersThunk {
    userId: number;
    ticketId: number;
}

export interface ITicketEditRemindersState {
    reminders: TicketReminderV1Attributes[];
    isLoadingFetch: boolean;
    isLoadingAdd: boolean;
    isLoadingRemove: boolean;
}

export const fetchReminders = createAsyncThunk<any, IRemindersThunk, {rejectValue: any}>(
    "ticketEditReminder/fetchReminders",
    async ({userId, ticketId}, {rejectWithValue}) => {
        try {
            const response = await ticketingService.fetchReminders(userId, ticketId);
            return response;
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export const addReminder = createAsyncThunk<any, TicketReminderV1Attributes, {rejectValue: any}>(
    "ticketEditReminder/addReminders",
    async (body, {rejectWithValue}) => {
        try {
            const response = await ticketingService.addReminder(body);
            return response;
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export const removeReminder = createAsyncThunk<number, number, {rejectValue: any}>(
    "ticketEditReminder/removeReminder",
    async (reminderId, {rejectWithValue}) => {
        try {
            await ticketingService.removeReminder(reminderId);
            return reminderId;
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

const ticketEditRemindersSlice = createSlice({
    name: "followerEditReminders",
    initialState: {
        reminders: [],
        isLoadingFetch: false,
        isLoadingAdd: false,
        isLoadingRemove: false,
    } as ITicketEditRemindersState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReminders.pending, (state: ITicketEditRemindersState) => {
                state.isLoadingFetch = true;
            })
            .addCase(fetchReminders.rejected, (_state: ITicketEditRemindersState, {payload}: {payload: string}) => {
                enqueueError({logInfo: TICKET_REMINDER_GET, error: Error(payload)});
            })
            .addCase(fetchReminders.fulfilled, (state: ITicketEditRemindersState, {payload}: {payload: any}) => {
                state.isLoadingFetch = false;
                state.reminders = payload;
            })
            .addCase(addReminder.pending, (state: ITicketEditRemindersState) => {
                state.isLoadingAdd = true;
            })
            .addCase(addReminder.rejected, (_state: ITicketEditRemindersState, {payload}: {payload: string}) => {
                enqueueError({logInfo: TICKET_REMINDER_ADD, error: Error(payload)});
            })
            .addCase(addReminder.fulfilled, (state: ITicketEditRemindersState, {payload}: {payload: TicketReminderV1Attributes}) => {
                const remindersUpdated = [...state.reminders, payload];
                const sortByDisplayDate = (a: TicketReminderV1Attributes, b: TicketReminderV1Attributes) =>
                    new Date(a.display_date).getTime() - new Date(b.display_date).getTime();
                state.reminders = remindersUpdated.sort(sortByDisplayDate);
                state.isLoadingAdd = false;
                enqueueSuccess({logInfo: TICKET_REMINDER_ADD, data: payload});
            })
            .addCase(removeReminder.pending, (state: ITicketEditRemindersState) => {
                state.isLoadingRemove = true;
            })
            .addCase(removeReminder.rejected, (_state: ITicketEditRemindersState, {payload}: {payload: string}) => {
                enqueueError({logInfo: TICKET_REMINDER_DELETE, error: Error(payload)});
            })
            .addCase(removeReminder.fulfilled, (state: ITicketEditRemindersState, {payload}: {payload: number}) => {
                state.reminders = state.reminders.filter((reminder: TicketReminderV1Attributes) => reminder.id !== payload);
                state.isLoadingRemove = false;
                enqueueSuccess({logInfo: TICKET_REMINDER_DELETE});
            });
    },
});

export default ticketEditRemindersSlice.reducer;
