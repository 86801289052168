import ticketEditAssignmentReducer, {ITicketEditAssignmentState} from "./ticketEditAssignmentSlice";
import ticketEditAttachmentsReducer, {ITicketEditAttachmentsState} from "./ticketEditAttachmentsSlice";
import ticketEditFollowersReducer, {ITicketEditFollowersState} from "./ticketEditFollowersSlice";
import ticketEditRemindersReducer, {ITicketEditRemindersState} from "./ticketEditRemindersSlice";
import ticketEditTimesReducer, {ITicketEditTimesState} from "./ticketEditTimesSlice";
import {configureStore, EnhancedStore} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import ticketEditHeaderReducer, {ITicketEditHeaderState} from "store/ticketEditHeaderSlice";
import ticketEditHistoryReducer, {ITicketEditHistoryState} from "store/ticketEditHistorySlice";
import ticketEdiInfoModalReducer, {ITicketEditInfoModalState} from "store/ticketEditInfoModalSlice";
import ticketEdiInfoReducer, {ITicketEditInfoState} from "store/ticketEditInfoSlice";
import ticketEditPasscodesReducer, {ITicketEditPassCodesState} from "store/ticketEditPassCodesSlice";
import ticketEditReducer, {ITicketEditState} from "store/ticketEditSlice";
import ticketEditUnitReducer, {ITicketEditUnitState} from "store/ticketEditUnitSlice";
import ticketSearchReducer, {ITicketSearchState} from "store/ticketSearchSlice";

interface IGlobalStore {
    ticketEdit: ITicketEditState;
    ticketEditAssignment: ITicketEditAssignmentState;
    ticketEditAttachments: ITicketEditAttachmentsState;
    ticketEditFollowers: ITicketEditFollowersState;
    ticketEditHeader: ITicketEditHeaderState;
    ticketEditHistory: ITicketEditHistoryState;
    ticketEditInfo: ITicketEditInfoState;
    ticketEditInfoModal: ITicketEditInfoModalState;
    ticketEditReminders: ITicketEditRemindersState;
    ticketEditTimes: ITicketEditTimesState;
    ticketEditUnit: ITicketEditUnitState;
    ticketEditPasscodes: ITicketEditPassCodesState;
    ticketSearch: ITicketSearchState;
}

export const store: EnhancedStore<IGlobalStore, any> = configureStore({
    reducer: {
        ticketEdit: ticketEditReducer,
        ticketEditAssignment: ticketEditAssignmentReducer,
        ticketEditAttachments: ticketEditAttachmentsReducer,
        ticketEditFollowers: ticketEditFollowersReducer,
        ticketEditHeader: ticketEditHeaderReducer,
        ticketEditHistory: ticketEditHistoryReducer,
        ticketEditInfo: ticketEdiInfoReducer,
        ticketEditInfoModal: ticketEdiInfoModalReducer,
        ticketEditReminders: ticketEditRemindersReducer,
        ticketEditTimes: ticketEditTimesReducer,
        ticketEditUnit: ticketEditUnitReducer,
        ticketEditPasscodes: ticketEditPasscodesReducer,
        ticketSearch: ticketSearchReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
