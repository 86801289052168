import React, {LegacyRef, useState} from "react";
import styles from "./MultiSelectFilter.module.scss";
import Multiselect from "multiselect-react-dropdown";
import { ReactComponent as IconArrow } from "../../assets/icons/arrow.svg"
import { ReactComponent as IconTimes } from "../../assets/icons/times.svg"
import { datadogLogs } from "@datadog/browser-logs";
import { FilterOption } from "typing/request";
import useURLParams from 'hooks/useURLParams';
import useCallbackWithDebounce from "hooks/useCallbackWithDebounce";

interface MultiSelectFilterProps {
    disable?: boolean;
    id?: string;
    minimumCharacters?: number;
    onFetchData?: (searchString: string) => Promise<FilterOption[] | void>;
    onUpdate: (selectedList: FilterOption[], filterId: string) => void;
    options?: FilterOption[];
    placeholder?: string;
    setOptions?: React.Dispatch<React.SetStateAction<FilterOption[]>>;
    title?: string;
    selectedValues?: FilterOption[];
    urlParams?: boolean; 
}

export const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({
    disable,
    id,
    minimumCharacters,
    onFetchData,
    onUpdate,
    options,
    placeholder,
    setOptions,
    title,
    selectedValues = [],
    urlParams = true,
}: MultiSelectFilterProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const filterId: string = id ?? 'filter-id';
    const { setURLParam } = useURLParams(`filter[${filterId}]`);
    const minChars: number =  minimumCharacters ?? 3;
    const multiselectRef:LegacyRef<Multiselect> = React.createRef();
    options = options ?? [];
    placeholder = placeholder ?? 'Start to type...'

    const clearOptions = (): void => {
        if (setOptions) setOptions([]);
    };

    const valueWrapper = (value: string) => {
        const valueMaxChars = 15;
        return value.length > valueMaxChars ? value.substring(0, valueMaxChars) : value;
    }

    const updateResults = (selectedList: FilterOption[]): void => {
        clearOptions();
        const selectedValues: string = selectedList.map((item: FilterOption) => item.id).join(',');
        if (urlParams) setURLParam(selectedValues);
        onUpdate(selectedList, filterId);
    };

    const search = useCallbackWithDebounce(async (searchString: string): Promise<void> => {
        if (loading || searchString.length < minChars) return;
        setLoading(true);
        try {
            if (!onFetchData) return;
            const results: void | FilterOption[] = await onFetchData(searchString);
            if (results && setOptions) setOptions(results);
        } catch(e) {
            if (!(e instanceof Error)) throw e;
            datadogLogs.logger.error("MultiSelectFilter.search", {}, e);
        } finally {
            setLoading(false);
        }
    });

    return ( 
        <div className={styles.content}>
            { title && <h4 data-testid="multi-select-filter-title" className={styles["filter-title"]}>{title}</h4>}
            <Multiselect
                placeholder={placeholder}
                options={options}
                displayValue="name"
                showArrow={true}
                customArrow={<IconArrow />}
                customCloseIcon={<IconTimes />}
                id={filterId}
                onSelect={updateResults}
                onRemove={updateResults}
                onSearch={search}
                loading={loading}
                ref={multiselectRef}
                disable={disable}
                selectedValueDecorator={valueWrapper}
                selectedValues={selectedValues}
                hidePlaceholder
            />
        </div>
    );
}
