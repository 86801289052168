import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { Modal } from "@vacasa/react-components-lib";
import { fetchHistory } from "store/ticketEditHistorySlice";
import {
    reassign,
    fetchAssignedTo
} from "store/ticketEditAssignmentSlice";
import styles from "./TicketEditAssignmentModal.module.scss";
import { isNil } from "lodash";
import { ThunkDispatch } from "@reduxjs/toolkit";
import classNames from "classnames";
import Alert from "@mui/material/Alert";
import { LoaderButton } from "components/loader-button/LoaderButton";
import { SearchAssignableUsersBar, UserItemType } from "components/search-assignable-users-bar/SearchAssignableUsersBar";
import TicketingService from "services/TicketingService";
import { fetchTicket, silentLoading } from "store/ticketEditSlice";

interface TicketEditAssignmentModalProps{
    ticketId: number,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const TicketEditAssignmentModal: React.FC<TicketEditAssignmentModalProps> = (
    {ticketId, setIsModalOpen}
) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const currentUserId = TicketingService.getInstance().currentUserId;

    const isLoadingReassign = useSelector((state: RootState) => state.ticketEditAssignment.isLoadingReassign);

    const [selected, setSelected] = useState<UserItemType | null>(null);

    const isInvalidCurrentUserId = !currentUserId;
    const isInvalidSelected = isNil(selected);
    const isInvalid = [isInvalidSelected, isInvalidCurrentUserId].some(validation => validation);

    const silentReload = async () => {
        await dispatch(silentLoading());
        await dispatch(fetchTicket(ticketId));
    };

    const handleReassign = async () => {
        if (isNil(selected)) return; // if no selected then quit
        try {
            const response = await dispatch(reassign({ticketId, assignTo: selected.id})).unwrap();
            await silentReload();
            setIsModalOpen(false);
            dispatch(fetchAssignedTo(response?.assigned_to));
            dispatch(fetchHistory(ticketId));
            setSelected(null);
        } catch {
            setSelected(null);
        }
    }

    return (
        <Modal showModal={true} canExit={true} setShowModal={setIsModalOpen} size="medium">
            <div className={styles.modal} data-testid="ticket-edit-assignment-modal">
                <h4 className="mb20">Reassign Ticket</h4>

                { /* Current User ID Validator */}
                { isInvalidCurrentUserId && (
                    <div data-testid="current-user-id-error">
                        <Alert severity="error">
                            Error getting your user id and it is necessary to reassign.<br />
                            Try reloading the page.
                        </Alert>
                    </div>
                )}

                <p className="mb15">Reassign this ticket to a new user.</p>
                <label htmlFor="assignable-users-combo-box">ASSIGN TO*</label>
                <SearchAssignableUsersBar
                    onSelected={setSelected}
                    disabled={isLoadingReassign}
                />

                <div className={styles["assignment-actions-box"]}>
                    <button
                        data-testid="cancel-reassign-button"
                        className={classNames("button button-primary", styles["cancel-btn"])}
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        id="reassign-button-submit"
                        variant="primary-inverse"
                        disabled={isInvalid}
                        onClick={() => handleReassign()}
                        className={styles["reassign-btn"]}
                    >
                        Reassign
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
