import React from "react";
import styles from "./Header.module.scss";
import { ReactComponent as IconVacasaLogoHoz } from "../../assets/icons/vacasa-logo-horizontal.svg"

export const Header: React.FC = () => {
    return (
        <div className={styles.header}>
            <IconVacasaLogoHoz  data-testid="header-logo"/>
        </div>
    )
}
