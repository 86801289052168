import React, { useEffect } from 'react';
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import { useSelector } from 'react-redux';
import { useAppDispatch } from "store/store";
import { setOrderBy, setReload, deleteOrderBy } from "store/ticketSearchSlice";
import styles from "./TableSortLabel.module.scss"
import type { RootState } from "store/store";
import type { SearchSortOrder, SearchOrderByQueryParams } from "typing/request";
import useURLParams from 'hooks/useURLParams';
import { SearchOrderKeysEnum } from 'appConstants';

interface ITableSortLabelProps {
    children: React.ReactNode;
    sortProperty: SearchOrderKeysEnum;
    shouldSetQueryParams?: boolean;
}

const enum SortOrderEnum {
    ASCENDING = "asc",
    DESCENDING = "desc",
}


export const TableSortLabel: React.FC<ITableSortLabelProps> = ({ children, sortProperty, shouldSetQueryParams = true }) => {

    const dispatch = useAppDispatch();
    const { searchParams, addSortingParam, removeSortingParam } = useURLParams('sort');
    const orderBy: SearchOrderByQueryParams = useSelector((state: RootState) => state.ticketSearch.orderBy);

    const sortHandler = (property: SearchOrderKeysEnum) => (_event: React.MouseEvent<unknown>) => {
        const currentOrder: SearchSortOrder | '' = orderBy[property] || '';
        let newOrderBy: { [property: string]: SearchSortOrder } | null = null;

        switch (currentOrder) {
            case SortOrderEnum.DESCENDING:
                newOrderBy = { [property]: SortOrderEnum.ASCENDING };
                break;
            case SortOrderEnum.ASCENDING:
                newOrderBy = null;
                break;
            default:
                newOrderBy = { [property]: SortOrderEnum.DESCENDING };
                break;
        }

        if (newOrderBy !== null) {
            if (shouldSetQueryParams) addSortingParam(newOrderBy);
            dispatch(setOrderBy(newOrderBy));
        } else {
            removeSortingParam(property)
            dispatch(deleteOrderBy(property));
        }

        dispatch(setReload());
    };

    useEffect(() => {
        const renderWithQueryParams = () => {
            const currentParamValues = searchParams.get("sort")?.split(",") ?? [];
            const currentSortingValue = currentParamValues.find(sortingValue => sortingValue.replace("-", "") === sortProperty)

            if (!currentParamValues || !currentSortingValue) return

            const paramOrder = currentSortingValue.startsWith('-') ? SortOrderEnum.DESCENDING : SortOrderEnum.ASCENDING
            const paramName = currentSortingValue.replace("-", "")
            dispatch(setOrderBy({ [paramName]: paramOrder }));
        }
        renderWithQueryParams()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MuiTableSortLabel
            active={(sortProperty in orderBy)}
            direction={(sortProperty in orderBy) ? orderBy[sortProperty] : SortOrderEnum.DESCENDING}
            onClick={sortHandler(sortProperty)}
        >
            {children}
            {(sortProperty in orderBy) ? (
                <span className={styles.hidden}>
                    {orderBy[sortProperty] === SortOrderEnum.DESCENDING ? 'sorted descending' : 'sorted ascending'}
                </span>
            ) : null}
        </MuiTableSortLabel>
    );
}
