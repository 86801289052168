import React, {useState} from "react";
import { RootState } from "store/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Modal, DatePicker } from "@vacasa/react-components-lib";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useDispatch, useSelector} from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { addTimes } from "store/ticketEditTimesSlice";
import Alert from '@mui/material/Alert';
import styles from "./TicketEditTimesModal.module.scss";
import moment from "moment";
import { fetchHistory } from "store/ticketEditHistorySlice";
import { fetchTimes } from "store/ticketEditTimesSlice";
import { CommentForm } from "components/comment-form/CommentForm";
import {OWNER_COMMENT_PLACEHOLDER, VisibilityStatusEnum} from "appConstants";
import type { Moment } from "moment";
import TicketingService from "services/TicketingService";
import { iframeScrollIntoElement } from "utils/helpers";

interface TicketEditTimesModalProps{
    ticketId: number,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const TicketEditTimesModal: React.FC<TicketEditTimesModalProps> = ({ticketId, setIsModalOpen}) => {
    const elementId = "edit-times-modal";
    const currentUserId = TicketingService.getInstance().currentUserId;
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isLoadingAdd = useSelector((state: RootState) => state.ticketEditTimes.isLoadingAdd);
    const [date, setDate] = useState<Date>(new Date());
    const [fromTime, setFromTime] = useState<Moment | null>(null);
    const [toTime, setToTime] = useState<Moment | null>(null);
    const [datepickerOpen, setDatePickerOpen] = useState(false);
    const [fromTimepickerOpen, setFromTimepickerOpen] = useState(false);
    const [toTimepickerOpen, setToTimepickerOpen] = useState(false);
    const [note, setNote] = useState("");

    const timediff = (toTime && fromTime) ? moment.duration(toTime.diff(fromTime)).asHours() : 0;

    const acceptBtnLabelMap = new Map([
        [VisibilityStatusEnum.OWNER, "Add Time Entry & Comment to Owner"],
        [VisibilityStatusEnum.INTERNAL, "Add Time Entry & Comment"]
    ]);
    const placeholderMap = new Map<VisibilityStatusEnum, string>([
        [VisibilityStatusEnum.OWNER, OWNER_COMMENT_PLACEHOLDER],
        [VisibilityStatusEnum.INTERNAL, "*Required: Describe the actions you performed..."]
    ]);

    // Validators
    const isInvalidTime = toTime === null || fromTime === null; 
    const isInvalidTimediff = !isInvalidTime && timediff <= 0;
    const isInvalidComment = note.length === 0;
    const isInvalid = [isInvalidTimediff, isInvalidTime, isInvalidComment].some(validation => validation);

    /**
     * Handles Time Submission
     */
    const handleSubmit = async (_text: string, visibility: VisibilityStatusEnum) => {
        const staffonly = visibility === VisibilityStatusEnum.INTERNAL ? 0 : 1;
        await dispatch(addTimes({
            ticketId,
            userId: currentUserId,
            note,
            fromTime: fromTime?.format(),
            toTime: toTime?.format(),
            external: staffonly
        } ));
        setIsModalOpen(false);
        dispatch(fetchHistory(ticketId));
        dispatch(fetchTimes({ticketId, userId: currentUserId}));
    }

    const updateDate = (originalDate: Moment, refDate: Date): Moment => {
        return originalDate?.set({
            date: refDate.getDate(),
            month: refDate.getMonth(),
            year: refDate.getFullYear()
        })
    }

    const handleOnChangeDate = (dateValue: Date) => {
        if (fromTime) updateDate(fromTime, dateValue);
        if (toTime) updateDate(toTime, dateValue);
        setDate(dateValue)
        setDatePickerOpen(false);
    }

    const handleOnChangeFromToTime = (fromTimeValue: Moment | null) => {
        if (!fromTimeValue) return;
        updateDate(fromTimeValue, date);
        setFromTime(fromTimeValue);
    }

    const handleOnChangeToTime = (toTimeValue: Moment | null) => {
        if (!toTimeValue) return;
        updateDate(toTimeValue, date);
        setToTime(toTimeValue)
    }

    return (
        <Modal showModal={true} canExit={true} setShowModal={setIsModalOpen} size="medium">
            <div className={styles.modal} data-testid="add-times-modal" id={elementId}>
                <h4 className="mb20">Add a Time Entry</h4>
                <p className={styles.summary}>
                    Select a date and times to track time that will be billed to the unit and available in UKG.
                    An optional comment can be posted to Staff & Owner. Both time the entry and comment cannot be undone.
                </p>

                {/* Select Date */}
                <div data-testid="add-times-datepicker" className={styles["date-selector"]}>
                    <DatePicker
                        open={datepickerOpen}
                        label="Date"
                        placeholder="Time Entry Date"
                        value={date}
                        onChange={handleOnChangeDate}
                        onClick={() => setDatePickerOpen(true)}
                        onOpen={() => setDatePickerOpen(true)}
                        onClose={() => setDatePickerOpen(false)}
                        format="MM/dd/yyyy"
                        variant="inline"
                        disableToolbar={true}
                    />
                </div>

                {/* Select Time */}
                <div className={styles["time-selectors"]}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>

                        {/* Start Time */}
                        <div className={styles.starttime} data-testid="start-timepicker">
                            <label>Start Time</label>
                            <div
                                className={styles["hidden-trigger"]}
                                data-testid="start-timepicker-trigger"
                                onClick={() => {
                                    setFromTimepickerOpen(true);
                                    iframeScrollIntoElement(elementId); // prevent out of focus if iframe mode
                                }
                            }></div>
                            <TimePicker
                                open={fromTimepickerOpen}
                                onOpen={() =>  {
                                }}
                                onClose={() => setFromTimepickerOpen(false)}
                                onChange={handleOnChangeFromToTime}
                            />
                        </div>

                        {/* End Time */}
                        <div className={styles.endtime} data-testid="end-timepicker">
                            <label>End Time</label>
                            <div
                                data-testid="end-timepicker-trigger"
                                className={styles["hidden-trigger"]}
                                onClick={() => {
                                    setToTimepickerOpen(true);
                                    iframeScrollIntoElement(elementId); // prevent out of focus if iframe mode
                                }}
                            >
                            </div>
                            <TimePicker
                                open={toTimepickerOpen}
                                onClose={() => setToTimepickerOpen(false)}
                                onChange={handleOnChangeToTime}
                            />
                        </div>
                        <div className={styles.timediff} data-testid="timediff">
                           {Math.trunc(timediff*100)/100} hours 
                        </div>
                    </LocalizationProvider>
                </div>

                { /* Time Validator */}
                { isInvalidTimediff && (
                    <div data-testid="timediff-error">
                        <Alert severity="error">
                            <b>END TIME</b> cannot be lower or equal than <b>START TIME</b>
                        </Alert>
                    </div>
                )}

                { /* Publish Comments */}
                <div data-testid="edit-comments">
                    <CommentForm
                        maxLength={2000}
                        placeholder={placeholderMap}
                        onAccept={handleSubmit}
                        onCancel={() => setIsModalOpen(false)}
                        acceptBtnLabel={acceptBtnLabelMap}
                        disabled={isInvalid || isLoadingAdd}
                        onChange={setNote}
                    />
                </div>
            </div>
        </Modal>
    )
}
