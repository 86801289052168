import React from "react";
import styles from "./HeaderTitle.module.scss";
import classNames from "classnames";

interface HeaderTitleProps {
    children?: boolean | React.CElement<any, any> | (React.CElement<any, any> | string | boolean)[];
    title?: string;
    className?: string; 
}
interface HeaderTitleNamespace {
    Standard: React.FC<HeaderTitleProps>;
    Container: React.FC<HeaderTitleProps>;
    Left: React.FC<HeaderTitleProps>;
    Right: React.FC<HeaderTitleProps>;
}

export const HeaderTitle: HeaderTitleNamespace = {
    Standard: ({children, title, className}: HeaderTitleProps) => (
        <HeaderTitle.Container className={className}>
            <HeaderTitle.Left title={title}>
                {children}
            </HeaderTitle.Left>
        </HeaderTitle.Container>
    ),
    Container: ({children, className}: HeaderTitleProps) => (
        <div className={classNames(styles.upper, className)}>
            {children}
        </div> 
    ),
    Left: ({children, title, className}: HeaderTitleProps) => (
        <div className={classNames(styles.left, className)}>
            {title && <h1>{title}</h1>}
            {children}
        </div> 
    ),
    Right: ({children, className}: HeaderTitleProps) => (
        <div className={classNames(styles.right, className)}>
            {children}
        </div> 
    ),
};
