import {datadogLogs, Logger} from "@datadog/browser-logs";
import {AxiosInstance} from "axios";
import axios from "axios";
import axiosRetry from "axios-retry";
import jwt_decode from "jwt-decode";
import isNil from "lodash/isNil";

export class ImplicitFlowService {
    protected logger: Logger;

    // Axios Configurations
    private static axiosRetries = 2;
    private static axiosTimeout = 20000; // 20 seconds
    private currentExecutingRequests: {[key: string]: AbortController} = {};

    constructor(private apiServiceUrl: string) {
        this.logger = datadogLogs.logger;
    }

    get apiUrl(): string {
        return this.apiServiceUrl;
    }

    /**
     * Decodes the Current authenticated User  stored in the Access Token
     */
    static getAuthenticatedUser(): {email: string; name: string; given_name: string; family_name: string} {
        const token = localStorage.getItem("idToken"); // @ts-ignore
        return token && jwt_decode(token);
    }

    /**
     * Custom Axios Instance that handles retry/backoff for request failures.
     * Also authenticates every call.
     */
    async axiosInstance(): Promise<AxiosInstance> {
        const axiosInstance = axios.create();

        // set base configuration
        axiosInstance.interceptors.request.use((config) => {
            // set authorization headers
            config.headers["Content-Type"] = "application/vnd.api+json";
            config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
            config.headers["Accept-Language"] = "en"; // Used to allow CORS to ConnectAPI

            // set every request timeout
            config.timeout = config.timeout || ImplicitFlowService.axiosTimeout;

            // abort requests management
            if (isNil(config.url)) return config; // early return if url not found
            if (config.method !== "post" && this.currentExecutingRequests[config.url]) {
                // abort same previous requests
                const abortController = this.currentExecutingRequests[config.url];
                delete this.currentExecutingRequests[config.url];
                abortController.abort();
            }

            // save new controller for request
            const source = new AbortController();
            this.currentExecutingRequests[config.url] = source;

            return {
                ...config,
                signal: source.signal,
            };
        });

        axiosInstance.interceptors.response.use(
            (response) => {
                // clean controller request
                if (response.config.url && this.currentExecutingRequests[response.config.url]) {
                    delete this.currentExecutingRequests[response.config.url];
                }
                return response;
            },
            (error) => {
                // here you check if this is a cancelled request to drop it silently (without error)
                if (axios.isCancel(error)) {
                    return new Promise(() => {});
                }

                // here you clean the request
                if (this.currentExecutingRequests[error.config.url]) {
                    delete this.currentExecutingRequests[error.config.url];
                }

                return Promise.reject(error);
            }
        );

        // add retry/backoff configuration
        axiosRetry(axiosInstance, {
            retries: ImplicitFlowService.axiosRetries,
            retryDelay: axiosRetry.exponentialDelay,
            shouldResetTimeout: true, // fixes the timeout issue https://github.com/softonic/axios-retry/issues/164#issuecomment-1729651382
            retryCondition: (error): boolean => {
                if ([500, 501, 502].includes(error.response?.status || 0)) return true;
                if (axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.code === "ECONNABORTED") return true; // retry if timeout
                return false;
            },
        });

        return axiosInstance;
    }
}
