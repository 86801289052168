import {MenuProps} from "@material-ui/core";

export function truncateFilename(filename: string, maxLength: number): string {
    const fileParts: string[] = filename.split(".");
    const extension: string = fileParts.pop() || "";
    const name = fileParts.join(".");
    if (name.length + extension.length + 1 <= maxLength) return filename;

    const charsToShow = maxLength - extension.length - 3;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    // TODO: deprecated
    return name.substr(0, frontChars) + "..." + name.substr(name.length - backChars) + "." + extension;
}

// This will fix combobox menu items positioning https://stackoverflow.com/questions/57815745/positioning-of-menuitems-under-the-material-ui-select-component
export const muiMenuBottomProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    getContentAnchorEl: null,
} as Partial<MenuProps>;

/**
 * Check if we are currently living within an iframe
 * This is helpful to patch some unexpected behavior around modals
 */
function isIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

/**
 * Async scroll into an element
 * @param elementId
 * @returns
 */
export const iframeScrollIntoElement = (elementId: string) => {
    if (!isIframe()) return; // prevent if not an iframe
    setTimeout(() => document.getElementById(elementId)?.scrollIntoView(), 10);
};

/**
 * Function to slugify username text
 *
 * e.g: john.dóe to john.doe
 *
 * @param {string} username
 * @returns {string}
 */
export const slugifyUsername = (username: string) => {
    return username.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
