import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import { TicketEditHeader } from "components/ticket-edit-header/TicketEditHeader";
import { TicketEditSeverity } from "components/ticket-edit-severity/TicketEditSeverity";
import { TicketEditAssignment } from "components/ticket-edit-assignment/TicketEditAssignment";
import { TicketEditInfo } from "components/ticket-edit-info/TicketEditInfo";
import { TicketEditAttachments } from "components/ticket-edit-attachments/TicketEditAttachments";
import { TicketEditUnit } from "components/ticket-edit-unit/TicketEditUnit";
import { TicketEditDueDate } from "components/ticket-edit-due-date/TicketEditDueDate";
import { TicketEditDescription } from "components/ticket-edit-description/TicketEditDescription";
import { TicketEditComments } from "components/ticket-edit-comments/TicketEditComments";
import { TicketEditTimes } from "components/ticket-edit-times/TicketEditTimes";
import { TicketEditHistory } from "components/ticket-edit-history/TicketEditHistory";
import { TicketEditReminders } from "components/ticket-edit-reminders/TicketEditReminders";
import { TicketEditPassCodes } from "components/ticket-edit-passcodes/TicketEditPassCodes";
import { TicketEditFollowers } from "components/ticket-edit-followers/TicketEditFollowers";
import { useSelector, useDispatch} from "react-redux";
import { RootState } from "store/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { fetchTicket } from "store/ticketEditSlice";
import classNames from 'classnames';
import styles from "./TicketEdit.module.scss";
import { TicketEditSkeleton } from "components/ticket-edit-skeleton/TicketEditSkeleton";
import { ConnectService } from "services/ConnectService";
import { CurrentUserType } from "typing/dto";

export const TicketEdit: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {ticket_id: ticketIdParam} = useParams<{ticket_id: string}>();
    const ticketId = parseInt(ticketIdParam || "");
    const isEditable = useSelector((state: RootState) => state.ticketEdit.isEditable);
    const creator = useSelector((state: RootState) => state.ticketEdit.creator);
    const ticket = useSelector((state: RootState) => state.ticketEdit.ticket);
    const followers = useSelector((state: RootState) => state.ticketEditFollowers.followers);
    const unitTimezone = useSelector((state: RootState) => state.ticketEditUnit.unit?.timezone);
    const currentConnectUser = ConnectService.getInstance().currentUser;
    const currentUser: CurrentUserType = {
        id: currentConnectUser?.id || 0,
        firstName: currentConnectUser?.first_name || "",
        lastName: currentConnectUser?.last_name || ""
    };

    useEffect(() => {
        dispatch(fetchTicket(ticketId));
      }, [dispatch, ticketId]);

    // Display loader until
    if (!ticket) {
        return <TicketEditSkeleton />;
     }

    return (
        <div className={classNames(styles.container,"max-width")}>
            <TicketEditHeader ticket={ticket}/>
            <div className={styles.subheader}>
                <TicketEditAssignment userId={ticket.assigned_to} ticketId={ticketId} isEditable={isEditable} />
                <TicketEditSeverity ticketId={ticketId} isEditable={isEditable} />
                <TicketEditDueDate ticketId={ticketId} isEditable={isEditable}/>
            </div>
            <div className={styles.content}>
                <div className={styles["content-left"]}>
                    <TicketEditDescription ticketId={ticketId}/>
                    <TicketEditComments ticketId={ticketId}/>
                    <TicketEditHistory ticketId={ticketId} unitId={ticket.unit_id}/>
                </div>
                <div className={styles["content-right"]}>
                    <TicketEditReminders ticketId={ticketId} isEditable={isEditable} />
                    <TicketEditPassCodes userId={currentUser.id} ticketId={ticketId} unitId={ticket.unit_id} unitTimezone={unitTimezone} />
                    <TicketEditInfo ticketAttr={ticket}/>
                    <TicketEditUnit unitId={ticket.unit_id} />
                    <TicketEditAttachments ticketId={ticketId} isEditable={isEditable} />
                    <TicketEditTimes
                        ticketId={ticketId}
                        started={ticket.start_date}
                        completed={ticket.completed_date}
                        void={ticket.void}
                        due={ticket.completion_date}
                        created={ticket.creation_date}
                        creator={creator}
                    />
                    <TicketEditFollowers ticketId={ticketId} followers={followers} currentUser={currentUser}/>
                </div>
            </div>
        </div>
    );
}
