import Skeleton from '@mui/material/Skeleton';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Stack } from "@mui/material";

interface ITicketSearchSkeletonProps {
    filterPerRow?: number,
    filterRows?: number,
}

export const TicketSearchSkeleton = ({filterPerRow = 4, filterRows = 1}: ITicketSearchSkeletonProps) => {
    const rowCount = 5;
    const columnCount = 1;
    return (
    <section data-testid="ticket-search-skeleton" className='max-width'>
        <Box padding={2}>
            {Array.from(new Array(filterRows)).map(() => (
                <Grid container spacing={2}>
                    {Array.from(new Array(filterPerRow)).map(() => (
                        <Grid item xs={12} sm={6} md={3}>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="rectangular" width="100%" height={50} />
                        </Grid>
                    ))}
                </Grid>
            ))}
        </Box>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                    {Array.from(new Array(columnCount)).map((_, index) => (
                        <TableCell key={index}>
                        <Skeleton variant="text" width="100%" height={70} />
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                
                <TableBody>
                    {Array.from(new Array(rowCount)).map((_, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {Array.from(new Array(columnCount)).map((_, colIndex) => (
                        <TableCell key={colIndex}>
                            <Skeleton variant="rectangular" width="100%" height={60} />
                        </TableCell>
                        ))}
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center" padding={2}>
            <Skeleton variant="text" width={90} height={50} />
            <Skeleton variant="rectangular" width={60} height={32} />
            <Skeleton variant="text" width={70} height={50} />
            <Skeleton variant="rectangular" width={32} height={32} />  
            <Skeleton variant="rectangular" width={32} height={32} />
        </Stack>
    </section>
    )
}
