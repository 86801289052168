import React, { useState } from "react";
import moment from "moment";
import { DatePicker, Modal } from '@vacasa/react-components-lib';
import { LoaderButton } from "components/loader-button/LoaderButton";
import styles from "./TicketEditHeaderSnoozeModal.module.scss";

type TimeInterval = "day" | "week";

interface ITicketEditHeaderSnoozeModalProps {
    showModal: boolean;
    onClose: () => Promise<any>;
    onAccept: (date: Date) => Promise<any>;
}

export const TicketEditHeaderSnoozeModal: React.FC<ITicketEditHeaderSnoozeModalProps> = ({ showModal, onClose, onAccept }) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [date, setDate] = useState<Date>(new Date());

    const handleAccept = async(time?: TimeInterval): Promise<void> => {
        let result = date;
        if (time === "day") result = moment(new Date()).add(1, "day").toDate();
        if (time === "week") result = moment(new Date()).add(1, "week").toDate();
        await onAccept(result);
        await onClose();
    };

    return (
        <Modal
            showModal={showModal}
            setShowModal={onClose}
            size="medium"
        >
            <div className={styles.modal} data-testid="snooze-modal">
                <h1>Snooze</h1>
                <section className={styles.body}>
                    <p>Remove this ticket from your active tickets until changed or woken up?</p>
                    <aside>
                        <LoaderButton id="snooze-modal-day-btn" onClick={() => handleAccept("day")}>Tomorrow</LoaderButton>
                        <LoaderButton id="snooze-modal-week-btn" onClick={() => handleAccept("week")}>Next week</LoaderButton>
                        <DatePicker
                            autoOk={true}
                            className={styles.datepicker}
                            open={datePickerOpen}
                            placeholder="Pick a date"
                            value={date}
                            onChange={(value: any) => setDate(value)}
                            onClick={() => setDatePickerOpen(true)}
                            onOpen={() => setDatePickerOpen(true)}
                            onClose={() => setDatePickerOpen(false)}
                            format="MM/dd/yyyy"
                            variant="inline"
                            disableToolbar={true}
                        />
                    </aside>
                </section>
                <section className={styles.actions}>
                    <LoaderButton id="snooze-modal-cancel-btn" onClick={onClose}>Cancel</LoaderButton>
                    <LoaderButton id="snooze-modal-accept-btn" variant="primary-inverse" onClick={() => handleAccept()}>Snooze</LoaderButton>
                </section>
            </div>
        </Modal>
    )
}
