import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EVENT_LOG_DATA} from "appConstants";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import {SmartHomeService} from "services/SmartHomeService";
import {DeviceGatewaysAttributes, PassCodesListAttributes} from "typing/dto";
import {DataType} from "typing/request";
import {enqueueError, enqueueSuccess, getErrorMessage} from "utils/message";

const {TICKET_PASSCODE_REVEAL, TICKET_PASSCODE_ADD, TICKET_PASSCODE_UPDATE, TICKET_PASSCODE_DELETE} = EVENT_LOG_DATA;

// Service Singletons
const smartHomeService = SmartHomeService.getInstance();

interface IDevicesAndPassCodesThunk {
    ticketId: number;
    unitId: number;
}

interface IPassCodeRevealThunk {
    userId: number;
    passCodeId: number;
}

export interface ITicketEditPassCodesState {
    deviceGateways: DataType<DeviceGatewaysAttributes>[] | null;
    hasActiveDevices: boolean | null;
    passCodes: DataType<PassCodesListAttributes>[] | null;
    accessCodes: {
        [key: number]: {
            isLoading: boolean;
            code: string | null;
        };
    };
    isFetching: boolean;
    isAdding: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
}

interface IDevicesAndPassCodesThunkResponse {
    deviceGateways: DataType<DeviceGatewaysAttributes>[];
    passCodes: DataType<PassCodesListAttributes>[];
}

export interface IPassCodeAddThunk {
    deviceGatewaysIds: number[];
    ticketId: number;
    currentUserId: number;
    user: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
    };
    startTime: string;
    endTime: string;
}

interface IPassCodeUpdateThunk {
    userId: number;
    passCodeId: number;
    startTime: string;
    endTime: string;
}

interface IPassCodeDeleteThunk {
    userId: number;
    passCodeId: number;
}

/**
 * Fetch all active device gateways from units and passcodes by user_type="Contractor" associated to them
 */
export const fetchDeviceGatewaysAndPassCodes = createAsyncThunk<any, IDevicesAndPassCodesThunk, {rejectValue: any}>(
    "ticketEditPassCodes/fetchDeviceGateways",
    async ({ticketId, unitId}, {rejectWithValue}): Promise<IDevicesAndPassCodesThunkResponse> => {
        try {
            const deviceGateways: DataType<DeviceGatewaysAttributes>[] | null = await smartHomeService.getDeviceGateways({
                property_id: unitId,
                active: 1,
            });
            let passCodes: DataType<PassCodesListAttributes>[] | null = [];
            if (!isEmpty(deviceGateways)) {
                passCodes = await smartHomeService.getPassCodes({active: 1, user_type: "Contractor", external_reference_id: ticketId});
            }
            return {
                deviceGateways: isNil(deviceGateways) ? [] : deviceGateways,
                passCodes: isNil(passCodes) ? [] : passCodes,
            };
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Create passcode for each active device gateway
 */
export const addPassCode = createAsyncThunk<any, IPassCodeAddThunk, {rejectValue: any}>(
    "ticketEditPassCodes/addPassCode",
    async (payload: IPassCodeAddThunk, {rejectWithValue}): Promise<any> => {
        try {
            return await Promise.all(
                payload.deviceGatewaysIds.map((deviceId) => {
                    return smartHomeService.addPassCode(payload.currentUserId, {
                        device_gateway: deviceId,
                        external_reference_id: payload.ticketId.toString(),
                        external_user_id: payload.user.id.toString(),
                        first_name: payload.user.firstName,
                        last_name: payload.user.lastName,
                        email: payload.user.email,
                        phone: payload.user.phone,
                        user_type: "Contractor",
                        start_time: payload.startTime,
                        end_time: payload.endTime,
                        operator_id: payload.currentUserId.toString(),
                    });
                })
            );
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Update passcode by id
 */
export const updatePassCode = createAsyncThunk<any, IPassCodeUpdateThunk, {rejectValue: any}>(
    "ticketEditPassCodes/updatePassCode",
    async ({userId, passCodeId, startTime, endTime}, {rejectWithValue}): Promise<any> => {
        try {
            return await smartHomeService.patchPassCode(userId, passCodeId, {
                start_time: startTime,
                end_time: endTime,
            });
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Soft delete passcode by id
 */
export const removePassCode = createAsyncThunk<any, IPassCodeDeleteThunk, {rejectValue: any}>(
    "ticketEditPassCodes/removePassCode",
    async ({userId, passCodeId}, {rejectWithValue}) => {
        try {
            return await smartHomeService.removePassCode(userId, passCodeId);
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Reveal a passcode getting by passcode id and return access code string
 */
export const revealPassCode = createAsyncThunk<any, IPassCodeRevealThunk, {rejectValue: any}>(
    "ticketEditPassCodes/revealPassCode",
    async ({userId, passCodeId}, {rejectWithValue}): Promise<string | null> => {
        try {
            const passCode = await smartHomeService.getPassCodeById(userId, passCodeId);
            return passCode?.attributes.access_codes[0].access_code ?? null;
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

const ticketEditPassCodesSlice = createSlice({
    name: "ticketEditPassCodes",
    initialState: {
        deviceGateways: null,
        hasActiveDevices: null,
        passCodes: null,
        accessCodes: {},
        isFetching: false,
        isAdding: false,
        isUpdating: false,
        isRemoving: false,
    } as ITicketEditPassCodesState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeviceGatewaysAndPassCodes.pending, (state: ITicketEditPassCodesState) => {
                state.isFetching = true;
            })
            .addCase(fetchDeviceGatewaysAndPassCodes.rejected, (state: ITicketEditPassCodesState) => {
                state.isFetching = false;
            })
            .addCase(fetchDeviceGatewaysAndPassCodes.fulfilled, (state: ITicketEditPassCodesState, {payload}: {payload: any}) => {
                state.isFetching = false;
                state.deviceGateways = payload.deviceGateways;
                state.passCodes = payload.passCodes;
                payload.passCodes.forEach((p: DataType<PassCodesListAttributes>) => (state.accessCodes[p.id] = {isLoading: false, code: null}));
                state.hasActiveDevices = !isEmpty(payload.deviceGateways);
            })
            .addCase(revealPassCode.pending, (state: ITicketEditPassCodesState, {meta}) => {
                state.accessCodes[meta.arg.passCodeId] = {isLoading: true, code: null};
            })
            .addCase(revealPassCode.rejected, (state: ITicketEditPassCodesState, {payload, meta}) => {
                state.accessCodes[meta.arg.passCodeId] = {isLoading: false, code: null};
                enqueueError({logInfo: TICKET_PASSCODE_REVEAL, error: Error(payload)});
            })
            .addCase(revealPassCode.fulfilled, (state: ITicketEditPassCodesState, {payload, meta}) => {
                state.accessCodes[meta.arg.passCodeId] = {isLoading: false, code: payload};
            })
            .addCase(addPassCode.pending, (state: ITicketEditPassCodesState) => {
                state.isAdding = true;
            })
            .addCase(addPassCode.rejected, (state: ITicketEditPassCodesState, {payload}) => {
                state.isAdding = false;
                enqueueError({logInfo: TICKET_PASSCODE_ADD, error: Error(payload)});
            })
            .addCase(addPassCode.fulfilled, (state: ITicketEditPassCodesState, {payload}) => {
                const newPasscodes = payload.map((p: any) => p.data);
                state.isAdding = false;
                state.passCodes = !isNil(state.passCodes) ? [...state.passCodes, ...newPasscodes] : newPasscodes;
                newPasscodes.forEach((p: any) => {
                    state.accessCodes[p.id] = {isLoading: false, code: null};
                });
                enqueueSuccess({logInfo: TICKET_PASSCODE_ADD, data: {payload}});
            })
            .addCase(removePassCode.pending, (state: ITicketEditPassCodesState) => {
                state.isRemoving = true;
            })
            .addCase(removePassCode.rejected, (state: ITicketEditPassCodesState, {payload}) => {
                state.isRemoving = false;
                enqueueError({logInfo: TICKET_PASSCODE_DELETE, error: Error(payload)});
            })
            .addCase(removePassCode.fulfilled, (state: ITicketEditPassCodesState, {meta}) => {
                const passCodeId = meta.arg.passCodeId;
                state.isRemoving = false;
                delete state.accessCodes[passCodeId];
                if (!isNil(state.passCodes)) state.passCodes = state.passCodes?.filter((passCode) => passCode.id !== passCodeId);
                enqueueSuccess({logInfo: TICKET_PASSCODE_DELETE, data: {passCodeId}});
            })
            .addCase(updatePassCode.pending, (state: ITicketEditPassCodesState) => {
                state.isUpdating = true;
            })
            .addCase(updatePassCode.rejected, (state: ITicketEditPassCodesState, {payload}) => {
                state.isUpdating = false;
                enqueueError({logInfo: TICKET_PASSCODE_UPDATE, error: Error(payload)});
            })
            .addCase(updatePassCode.fulfilled, (state: ITicketEditPassCodesState, {payload, meta}) => {
                state.isUpdating = false;
                if (!isNil(state.passCodes)) {
                    state.passCodes = state.passCodes.map((passCode) => {
                        if (passCode.id === meta.arg.passCodeId) return payload.data;
                        else return passCode;
                    });
                    delete state.accessCodes[meta.arg.passCodeId];
                    state.accessCodes[payload.data.id] = {isLoading: false, code: null};
                }
                enqueueSuccess({logInfo: TICKET_PASSCODE_UPDATE, data: {payload}});
            });
    },
});

export default ticketEditPassCodesSlice.reducer;
