import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { addReminder } from "store/ticketEditRemindersSlice";
import { Modal, DatePicker } from "@vacasa/react-components-lib";
import { LoaderButton } from "components/loader-button/LoaderButton";
import { fetchReminders } from "store/ticketEditRemindersSlice";
import TextField from '@material-ui/core/TextField';
import styles from './ReminderAddModal.module.scss'
import classNames from "classnames";
import { TICKET_FETCH_REMINDER_IS_MAINTENANCE } from "appConstants";
import { ConnectService } from "services/ConnectService";

interface ReminderAddModalProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    ticketId: number
}

export const ReminderAddModal: React.FC<ReminderAddModalProps> = ({ setShowModal, ticketId}) => {
    const currentUserId: number = ConnectService.getInstance().currentUserId;
    const [datepickerOpen, setDatepickerOpen] = useState<boolean>(false);
    const [reminderDate, setReminderDate] = useState<Date>(new Date());
    const [reminderText, setReminderText] = React.useState<string>('');
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const handleReminderTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReminderText(event.target.value);
    }

    const handleSubmit = async () => {
        const body = {
            ticket_id: ticketId,
            user_id: currentUserId,
            is_maintenance: TICKET_FETCH_REMINDER_IS_MAINTENANCE,
            display_date: reminderDate.toISOString(),
            message: reminderText
        };
        await dispatch(addReminder(body))
        dispatch(fetchReminders({userId: currentUserId, ticketId}));
        setShowModal(false)
    }

    return (
        <Modal showModal setShowModal={() => setShowModal(false)} size="large">
            <div className="modal">
                <h1>Add a Reminder</h1>
                <p>Select a date and add a ticket reminder. The reminder will display on the ticket and on the active <br/> ticket list page when the date is reached.</p>
                <form>
                    <div className={classNames("date-selector", styles["form-control"])}>
                        <label htmlFor="reminder-datepicker">
                            <b>DATE</b>
                        </label>
                        <DatePicker
                            id="reminder-datepicker"
                            open={datepickerOpen}
                            value={reminderDate}
                            onChange={(value)=> setReminderDate(value)}
                            onClick={() => setDatepickerOpen(true)}
                            onOpen={() => setDatepickerOpen(true)}
                            onClose={() => setDatepickerOpen(false)}
                            format="MM/dd/yyyy"
                            variant="inline"
                            disableToolbar={true}
                        />
                    </div>
                    <div className={classNames(styles.formControl)}>
                        <label htmlFor="reminder-input">
                            <b>REMINDER</b>
                        </label>
                        <TextField
                            id="reminder-input"
                            value={reminderText}
                            onChange={handleReminderTextChange}
                            variant="outlined"
                            inputProps={{ maxLength: 200 }}
                            fullWidth
                        />
                        <p className={styles["word-counter"]}>{reminderText.length}/200</p>
                    </div>
                </form>
                <div className="modal-actions">
                    <button
                        className="button button-primary"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        variant="primary-inverse"
                        onClick={handleSubmit}
                        disabled={!reminderText.length}
                    >
                        Snooze
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
