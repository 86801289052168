import React, {useState} from "react";
import styles from "./TicketSearchFiltersSlim.module.scss";
import { MultiSelectFilter } from "components/multi-select-filter/MultiSelectFilter";
import { RootState, useAppDispatch } from "store/store";
import { setReload, setSelectedFilterValues } from "store/ticketSearchSlice";
import { TicketingService } from "services/TicketingService";
import { FilterOption, SearchSelectedFilterValues, TicketingServiceFilter } from "typing/request";
import { useSelector } from "react-redux";
import { ListFilterEnum, STATUS_MAP } from "appConstants";
import { DatePicker } from "@vacasa/react-components-lib";

interface ITicketSearchFiltersSlimProps {
    filterQueryParams?: TicketingServiceFilter
}

export const TicketSearchFiltersSlim: React.FC<ITicketSearchFiltersSlimProps> = ({filterQueryParams}: ITicketSearchFiltersSlimProps) => {
    const dispatch = useAppDispatch();
    
    // This will be a new component (DatePickerFilter)
    const [datePickerOpen1, setDatePickerOpen1] = useState(false);
    const [date1, setDate1] = useState<Date>(new Date());
    const [datePickerOpen2, setDatePickerOpen2] = useState(false);
    const [date2, setDate2] = useState<Date>(new Date());

    const [titleOptions, setTitleOptions] = useState([] as FilterOption[]);
    const statusOptions = Array.from(STATUS_MAP, ([key, value]) => ({id: key, name: value}));
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );

    const ticketingService = TicketingService.getInstance();

    const updateList = async (selectedList: FilterOption[], filterId: string) => {
        // Crates a full string with the results 
        const selectedValues = selectedList.map(item => item.id).join(',');

        // Adds the filter by ID
        const paramFilter: { [key: string]: string } = {};
        paramFilter[filterId] = selectedValues;

        // Set filters selected values
        dispatch(setSelectedFilterValues({
            ...selectedFilterValues,
            [filterId]: selectedList
        }))

        // Reloads the page
        dispatch(setReload());
    };

    const fetchTicketsByTitle = async (searchString: string, withQueryParams: boolean = true): Promise<FilterOption[]> => {
        const shouldAddQueryParams = withQueryParams && filterQueryParams;
        const tickets = await ticketingService.getTickets({
            filter: {
                title: searchString,
                ...filterQueryParams && shouldAddQueryParams,
            }
        });
        return tickets ? tickets.data.map((ticket) => ({
            "name": ticket.attributes.title,
            "id": ticket.attributes.title
        })): [];
    };

    return (
        <div className={styles.content}>
            {/* This will be a new component (DatePickerFilter) */}
            <div className={styles.item}>
                <h4 style={{marginBottom: 10}}>Start Date</h4>
                <DatePicker
                    autoOk={true}
                    className={styles.datepicker}
                    open={datePickerOpen1}
                    placeholder="Pick a date"
                    value={date1}
                    onChange={(value: any) => setDate1(value)}
                    onClick={() => setDatePickerOpen1(true)}
                    onOpen={() => setDatePickerOpen1(true)}
                    onClose={() => setDatePickerOpen1(false)}
                    format="MM/dd/yyyy"
                    variant="inline"
                    disableToolbar={true}
                />
            </div>
            {/* This will be a new component (DatePickerFilter) */}
            <div className={styles.item}>
                <h4 style={{marginBottom: 10}}>End Date</h4>
                <DatePicker
                    autoOk={true}
                    className={styles.datepicker}
                    open={datePickerOpen2}
                    placeholder="Pick a date"
                    value={date2}
                    onChange={(value: any) => setDate2(value)}
                    onClick={() => setDatePickerOpen2(true)}
                    onOpen={() => setDatePickerOpen2(true)}
                    onClose={() => setDatePickerOpen2(false)}
                    format="MM/dd/yyyy"
                    variant="inline"
                    disableToolbar={true}
                />
            </div>
            <div className={styles.item}>
                <MultiSelectFilter
                    title="Status"
                    id={ListFilterEnum.STATUS}
                    options={statusOptions}
                    onUpdate={updateList}
                    selectedValues={selectedFilterValues[ListFilterEnum.STATUS]}
                    minimumCharacters={0}
                    urlParams={false}
                />
            </div> 
            <div className={styles.item}>
                <MultiSelectFilter
                    title="Ticket Name"
                    id={ListFilterEnum.TITLE}
                    options={titleOptions}
                    onFetchData={fetchTicketsByTitle}
                    onUpdate={updateList}
                    setOptions={setTitleOptions}
                    selectedValues={selectedFilterValues[ListFilterEnum.TITLE]}
                    urlParams={false}
                />
            </div>
        </div>
    );
}
