import {SearchOrderByQueryParams, SearchSelectedFilterValues} from "typing/request";

/**
 * Converts the `orderBy` object to an array of query parameter strings.
 *
 * @param {SearchOrderByQueryParams} orderBy - The object containing properties to sort by,
 * e.g. {'status': 'desc', 'creation_date': 'asc', ...}
 * @returns {string[]} - An array of strings representing the order-by parameters for a query.
 * e.g. ["-status", "creation_date", ...]
 */
export const orderByToParams = (orderBy: SearchOrderByQueryParams): string => {
    const queryParams = Object.entries(orderBy || {}).map(([property, order]) => {
        return order === "asc" ? property : `-${property}`;
    });
    return queryParams.join(",");
};

/**
 * Converts an object of selected filter values into query parameters.
 *
 * @param {SearchSelectedFilterValues} filters - An object where each key is a filter category,
 * and each value is an array of filter items containing an `id` property.
 * @returns {Record<string, string>} An object where each key is a filter category,
 * and each value is a comma-separated string of the selected filter item IDs.
 */
export const filtersToParams = (filters: SearchSelectedFilterValues): Record<string, string> => {
    return Object.fromEntries(Object.entries(filters).map(([key, value]) => [key, value.map((element) => element.id).join(",")]));
};
