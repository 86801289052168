import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { LoaderButton } from "components/loader-button/LoaderButton";
import styles from "./ConfirmDialog.module.scss";

interface ConfirmDialogProps {
    text: string,
    visible: boolean,
    onClose: () => any;
    onCancel?: () => any;
    onAccept?: () => any;
    title?: string;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({text, onClose, onCancel, onAccept, visible, title, ...rest}: ConfirmDialogProps) => {
    return (
        <div>
        { visible && 
            <Dialog
                className={styles['dialog']}
                open={visible}
                onClose={onClose}
                {...rest}
            >
                {title && <DialogTitle>
                    {title}
                </DialogTitle>}
                <DialogContent>
                    <DialogContentText>
                      {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles['dialog-actions']}>
                    <LoaderButton id="confirm-dialog-cancel" variant="secondary" onClick={() => onCancel ? onCancel() : onClose()}>No</LoaderButton>
                    { onAccept && <LoaderButton id="confirm-dialog-accept" variant="primary-inverse" onClick={() => onAccept()}>Yes</LoaderButton>}
                </DialogActions>
            </Dialog>
        }
        </div>
    );
};
