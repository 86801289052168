import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ConnectService} from "services/ConnectService";
import {UnitService} from "services/UnitService";
import {ConnectUnitAttributes, UnitV2Attributes} from "typing/dto";

// Service Singletons
const unitService = UnitService.getInstance();
const connectService = ConnectService.getInstance();

/**
 * Fetch Unit based on legacy unit id from Unit Service and Connect API (only for LOM and Operations Manager data)
 */
export const fetchUnit = createAsyncThunk(
    "ticketEditUnit/fetchUnit",
    async (
        id: number
    ): Promise<{
        unit: UnitV2Attributes | null;
        connectUnit: ConnectUnitAttributes | null;
    }> => {
        const [unit, connectUnit]: [UnitV2Attributes | null, ConnectUnitAttributes | null] = await Promise.all([
            unitService.getUnitByLegacyId(id),
            connectService.getConnectUnitByLegacyId(id),
        ]);
        return {unit, connectUnit};
    }
);

export interface ITicketEditUnitState {
    isLoading: boolean;
    unit: null | UnitV2Attributes;
    connectUnit: null | ConnectUnitAttributes;
}

const ticketEditSlice = createSlice({
    name: "unitEdit",
    initialState: {
        unit: null,
        connectUnit: null,
        isLoading: true,
    } as ITicketEditUnitState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUnit.pending, (state: ITicketEditUnitState) => {
                state.isLoading = true;
                state.unit = null;
                state.connectUnit = null;
            })
            .addCase(fetchUnit.fulfilled, (state: ITicketEditUnitState, {payload}: {payload: any}) => {
                state.isLoading = false;
                state.unit = payload.unit.attributes as UnitV2Attributes;
                state.connectUnit = payload.connectUnit.attributes as ConnectUnitAttributes;
            });
    },
});

export default ticketEditSlice.reducer;
