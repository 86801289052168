import {env} from "core/env";

/**
 * Service for Generating Url to Admin
 * Cerntralized URL service
 */
export class AdminService {
    private static baseUrl = env.REACT_APP_ADMIN_URL;

    /**
     * Url for Reservation Profile in Admin
     */
    static getReservationUrl(legacyReservationId: number): string {
        return `${AdminService.baseUrl}/admin/dashboard/reservation/${legacyReservationId}`;
    }

    /**
     * Url for Unit Profile in Admin
     */
    static getUnitUrl(legacyUnitId: number): string {
        return `${AdminService.baseUrl}/admin/dashboard/units/index?UnitID=${legacyUnitId}`;
    }

    /**
     * Url for Tickets belonging to a Unit
     */
    static getUnitTicketsUrl(legacyUnitId: number): string {
        return `${AdminService.baseUrl}/admin/dashboard/tickets/units?UnitID=${legacyUnitId}`;
    }

    /**
     * Url for Ticket belongs to a User
     */
    static getUserTicketsUrl(userId: number): string {
        return `${AdminService.baseUrl}/admin/dashboard/tickets/index?UserID=${userId}`;
    }
}
