/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import { TicketEditInfoModal } from "components/ticket-edit-info-modal/TicketEditInfoModal";
import { TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import { TicketV1Attributes } from "typing/dto";
import { TicketEntity } from "entities/ticket";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector, useDispatch} from "react-redux";
import { fetchInfo } from "store/ticketEditInfoSlice";
import { Spinner } from "components/spinner/Spinner";
import { RootState } from "store/store";
import { AdminService } from "services/AdminService";
import style from "./TicketEditInfo.module.scss";
import { useModalScroll } from "hooks/useModalScroll"; 

interface TicketEditInfoProps {
    ticketAttr: TicketV1Attributes;
}

export const TicketEditInfo: React.FC<TicketEditInfoProps> = ({ticketAttr}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const ticketEntity = new TicketEntity(ticketAttr);
    const [isModalOpen, setIsModalOpen] = useModalScroll(false);
    const isLoadingFetch = useSelector((state: RootState) => state.ticketEditInfo.isLoadingFetch);
    const dispositionThirdLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionThirdLevel);
    const dispositionSecondLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionSecondLevel);
    const dispositionFirstLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionFirstLevel);

    useEffect(() => {
        dispatch(fetchInfo(ticketAttr.disposition_id));
    },[dispatch, ticketAttr.disposition_id])
    
    const TicketEditInfoHeader = ({ options = true }) => (
        <div>
            <h2>TicketInfo</h2>

            {/* Only allow edits when in Open state */}
            <button
                className="btn-link"
                data-testid="edit-ticket-info-modal"
                onClick={() => setIsModalOpen(true)}
                disabled={!(options && ticketEntity.isOpen())}
            >
                Edit Ticket
            </button>
        </div>
    );

    // Display loader until
    if (isLoadingFetch) {
        return (
            <TicketBoxCollapsable
                header={<TicketEditInfoHeader options={false} />}>
                <Spinner/>
            </TicketBoxCollapsable>
        );
    }
    
    return ( 
        <>

            {/* Modal - force unmount if showModal === false */}
            {isModalOpen &&
                <TicketEditInfoModal
                    ticketId={Number(ticketAttr.id)}
                    setIsModalOpen={setIsModalOpen}
                    requestedBy={ticketAttr.requested_by}
                    dispositionId={ticketAttr.disposition_id}
                    frequency={ticketAttr.frequency}
                    reservationId={ticketAttr.reservation_id}
                    unitId={ticketAttr.unit_id}
                    reopenBasedOn={ticketAttr.recurring_reopen_type}
                    recurringBeforeDueDate={ticketAttr.recurring_days_before_due_date}
                    frequencyStartDate={ticketAttr.frequency_start_date}
                    />
            }
            
            <TicketBoxCollapsable header={<TicketEditInfoHeader />}>
                <div>
                    { ticketAttr.reservation_id && (<>
                        <b>Reservation #:</b> 
                        <a
                            href={AdminService.getReservationUrl(ticketAttr.reservation_id)}
                            target="_blank"
                            data-testid="reservation-id"
                            rel="noreferrer">{ticketAttr.reservation_id}
                        </a><br/>
                    </>)}
                    <b>Requested By:</b> <span data-testid="requested-by">{ ticketEntity.getRequestedBy().name }</span> <br/>
                    <b>Disposition:</b>
                    <div className={style.dispositions}>
                        <span data-testid="disposition-tier-one">{dispositionFirstLevel?.attributes.name}</span><br/>
                        <span data-testid="disposition-tier-two">{dispositionSecondLevel?.attributes.name}</span><br/>
                        { dispositionThirdLevel && (
                            <span data-testid="disposition-tier-three">
                                {dispositionThirdLevel?.attributes.name} <br/>
                            </span>
                        )}
                    </div> 
                    <b>Reason for Assignment:</b> <span data-testid="frequency-for-assignment">{ ticketEntity.getReasonForAssignment() }</span><br/>
                    { ticketAttr.frequency && (
                        <>
                            <b>Frequency of Recurrence:</b> <span data-testid="frequency-of-recurrence"> Every { ticketAttr.frequency } Days</span> <br/>
                        </>
                    )}
                </div>
            </TicketBoxCollapsable>
        </>
    );
}
