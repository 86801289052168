import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/store";
import {Modal} from "@vacasa/react-components-lib";
import {addPassCode, IPassCodeAddThunk} from "store/ticketEditPassCodesSlice";
import styles from "./PassCodesAddModal.module.scss";
import isNil from "lodash/isNil";
import {ThunkDispatch} from "@reduxjs/toolkit";
import classNames from "classnames";
import {LoaderButton} from "components/loader-button/LoaderButton";
import {SearchAssignableUsersBar, UserItemType} from "components/search-assignable-users-bar/SearchAssignableUsersBar";
import {Moment} from "moment/moment";
import moment from "moment-timezone";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DesktopDateTimePicker} from "@mui/x-date-pickers/DesktopDateTimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {ConnectService} from "services/ConnectService";
import Alert from "@mui/material/Alert";
import {DataType, GetResponse} from "typing/request";
import {DeviceGatewaysAttributes, UserConnectAttributes} from "typing/dto";
import { iframeScrollIntoElement } from "utils/helpers";

// Singletons
const connectService = ConnectService.getInstance();

interface PassCodesAddModalProps{
    userId: number;
    unitTimezone: string;
    ticketId: number;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PassCodesAddModal: React.FC<PassCodesAddModalProps> = (
    {userId, unitTimezone, ticketId, setShowModal}
) => {
    // hooks
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    // vars
    const nowUnitTime: Moment = moment.tz(moment(), unitTimezone);
    const elementId = "passcodes-add-modal";

    // state
    const isAdding: boolean = useSelector((state: RootState) => state.ticketEditPasscodes.isAdding);
    const deviceGateways: DataType<DeviceGatewaysAttributes>[] | null = useSelector((state: RootState) => state.ticketEditPasscodes.deviceGateways);
    const [selected, setSelected] = useState<UserItemType | null>(null);
    const [startTime, setStarTime] = useState<Moment | null>(nowUnitTime);
    const [endTime, setEndTime] = useState<Moment | null>(nowUnitTime);
    const [fromDatePickerOpen, setFromDatePickerOpen] = useState(false);
    const [toDatePickerOpen, setToDatePickerOpen] = useState(false);

    // Validators
    const isInvalidCurrentUserId: boolean = !userId;
    const isInvalidSelected: boolean = isNil(selected);
    const isInvalidDateRange: boolean = isNil(startTime) || isNil(endTime) || startTime === endTime;
    const isInvalid: boolean = [isInvalidSelected, isInvalidCurrentUserId, isInvalidDateRange].some(validation => validation);

    /**
     * Submits new passcode
     */
    const handleAdd = async (): Promise<void> => {
        if (isNil(selected) || isNil(deviceGateways)) return; // if no selected then quit
        try {
            const user: GetResponse<UserConnectAttributes> | null = await connectService.getUserById(selected.id);
            const payload: IPassCodeAddThunk = {
                ticketId,
                deviceGatewaysIds: deviceGateways.map(((dg: DataType<DeviceGatewaysAttributes>) => (dg.id))),
                currentUserId: userId,
                user: {
                    id: selected.id,
                    firstName: selected.firstName ?? "",
                    lastName: selected.lastName ?? "",
                    email: isNil(user) ? "" : user.data.attributes.email_address,
                    phone: isNil(user) ? "" : user.data.attributes.phone
                },
                startTime: !isNil(startTime) ? startTime.unix().toString() : "",
                endTime: !isNil(endTime) ? endTime.unix().toString() : ""
            }
            await dispatch(addPassCode(payload));
            setShowModal(false);
            setSelected(null);
        } catch {
            setSelected(null);
        }
    }

    /**
     * Handles Passcode Startime Change 
     */
    const handleOnChangeStartTime = async (value: Moment | null): Promise<void> => {
        setStarTime(value);
        if (isNil(endTime) || isNil(value) || endTime >= value) return; // validates starttime & endtime
        setEndTime(value);
    }

    /**
     * Hnadles Passcode Endtime change 
     */
    const handleOnChangeEndTime = async (value: Moment | null): Promise<void> => {
        setEndTime(value);
    }

    return (
        <Modal showModal={true} canExit={true} setShowModal={setShowModal} size="medium">
            <div className={styles.modal} data-testid="passcodes-add-modal" id={elementId}>
                <h4 className="mb20">Create Passcode</h4>

                <label htmlFor="assignable-users-combo-box">USER</label>
                <SearchAssignableUsersBar
                    onSelected={setSelected}
                    disabled={isAdding}
                />

                <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                    <div className={styles["datetime-selectors"]}>
                        <div className={styles.startdate}>
                            <label>START TIME (Local Unit Time)</label>
                            <div
                                data-testid="from-datetime-picker-trigger"
                                className={styles["hidden-trigger"]}
                                onClick={() => {
                                    setFromDatePickerOpen(true);
                                    iframeScrollIntoElement(elementId); // prevent out of focus if iframe mode
                                }
                            }></div>
                            <div className={styles['datetime-box']}>
                                <DesktopDateTimePicker
                                    open={fromDatePickerOpen}
                                    defaultValue={startTime}
                                    timezone={unitTimezone}
                                    onChange={handleOnChangeStartTime}
                                    minDateTime={nowUnitTime}
                                    onClose={() => setFromDatePickerOpen(false)}
                                />
                            </div>
                        </div>

                        <div className={styles.enddate}>
                            <label>END TIME (Local Unit Time)</label>
                            <div
                                data-testid="to-datetime-picker-trigger"
                                className={styles["hidden-trigger"]}
                                onClick={() => {
                                    setToDatePickerOpen(true);
                                    iframeScrollIntoElement(elementId); // prevent out of focus if iframe mode
                                }
                            }></div>
                            <div className={styles['datetime-box']}>
                                <DesktopDateTimePicker
                                    open={toDatePickerOpen}
                                    value={endTime}
                                    timezone={unitTimezone}
                                    onChange={handleOnChangeEndTime}
                                    onClose={() => setToDatePickerOpen(false)}
                                    minDateTime={startTime ?? nowUnitTime}
                                />
                            </div>
                        </div>
                    </div>
                </LocalizationProvider>

                {/* Alert if date range is incorrect */}
                {isInvalidDateRange &&
                    <Alert severity="error">
                        Start Time and End Time cannot be the same.
                    </Alert>
                }

                <div className={styles["assignment-actions-box"]}>
                    <button
                        data-testid="cancel-passcode-add-button"
                        className={classNames("button button-primary", styles["cancel-btn"])}
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        id="passcode-add-button-submit"
                        variant="primary-inverse"
                        disabled={isInvalid}
                        onClick={handleAdd}
                        className={styles["passcode-add-btn"]}
                    >
                        Create
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
