import {Configuration} from "core/configuration";
import React, {useState} from "react";

export const useModalScroll = (state: boolean): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
    const [isModalOpen, setIsModalOpen] = useState(state);

    const setIsModalOpenScroll = (isOpen: boolean) => {
        setIsModalOpen(isOpen);
        if (isOpen) {
            document.getElementById(Configuration.innerRootId)?.scrollIntoView();
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = "15px";
        } else {
            document.body.style.overflow = "initial";
            document.body.style.paddingRight = "0px";
        }
    };

    // @ts-ignore returns state, plus wrapper
    return [isModalOpen, setIsModalOpenScroll];
};
