/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styles from "./TicketEditComments.module.scss"
import { CommentForm } from "components/comment-form/CommentForm";
import { TicketingService } from "services/TicketingService";
import { enqueueError, enqueueSuccess, getErrorMessage } from "utils/message";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { slugifyUsername } from "utils/helpers";
import { fetchHistory } from "store/ticketEditHistorySlice";
import { ConnectService } from "services/ConnectService";
import { addFollower } from "store/ticketEditFollowersSlice";
import { EVENT_LOG_DATA, VisibilityStatusEnum, OWNER_COMMENT_PLACEHOLDER, TaggedUsernameRegex } from "appConstants";
import { RootState } from "store/store";

const ticketingService = TicketingService.getInstance();
const connectService = ConnectService.getInstance();

const {TICKET_COMMENT_ADD} = EVENT_LOG_DATA;

interface TicketEditCommentsProps {
    ticketId: number
}
export const TicketEditComments: React.FC<TicketEditCommentsProps> = ({ticketId}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const currentFollowers = useSelector((state: RootState) => state.ticketEditFollowers.filledFollowers);
    const acceptBtnLabelMap = new Map<VisibilityStatusEnum, string>([
        [VisibilityStatusEnum.OWNER, "Comment to Owner"],
        [VisibilityStatusEnum.INTERNAL, "Comment"]
    ]);
    const placeholderMap = new Map<VisibilityStatusEnum, string>([
        [VisibilityStatusEnum.OWNER, OWNER_COMMENT_PLACEHOLDER],
        [VisibilityStatusEnum.INTERNAL, "Write a comment..."]
    ]);

    const getUserTags = (comment: string) => new Set([...comment.matchAll(new RegExp(TaggedUsernameRegex.AT_EXCLUDED, 'g'))].map(match => match[1]));

    const addFollowersFromComment = async (comment: string) => {
        const usernames = [...getUserTags(comment)].map(username => slugifyUsername(username));

        if (usernames.length === 0) return

        const users = await connectService.getUsers({ username: { in: usernames.join(",") } });
        const newFollowers = users.filter(user => !currentFollowers?.map(follower => follower.id).includes(Number(user.id)))
        .map((user) => user.attributes);

        newFollowers?.forEach(async (follower) => {
            const {id, first_name, last_name} = follower;
            dispatch(addFollower({ ticketId, follower: {id, name: `${first_name} ${last_name}`} }));
        });
    }

    const onCommentAccept = async(text: string, visibility: VisibilityStatusEnum) => {
        try {
            const request = {
                "ticket_id": ticketId,
                "note": DOMPurify.sanitize(text),
                "external": Number(visibility === VisibilityStatusEnum.OWNER)
            };
            await ticketingService.addComment(request);
            addFollowersFromComment(request.note);
            dispatch(fetchHistory(ticketId));
            enqueueSuccess({logInfo: TICKET_COMMENT_ADD, data: request});
        } catch (error) {
            enqueueError({logInfo: TICKET_COMMENT_ADD, error: Error(getErrorMessage(error))});
        }
    };

    return ( 
        <div className={styles.content}>
            <CommentForm acceptBtnLabel={acceptBtnLabelMap} onAccept={onCommentAccept} maxLength={5000} placeholder={placeholderMap} />
        </div>
    );
}
