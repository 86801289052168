import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/store";
import {Modal} from "@vacasa/react-components-lib";
import {updatePassCode, removePassCode} from "store/ticketEditPassCodesSlice";
import styles from "./PassCodesUpdateModal.module.scss";
import { ReactComponent as IconTrash } from "../../assets/icons/trash.svg"
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import isNil from "lodash/isNil";
import {ThunkDispatch} from "@reduxjs/toolkit";
import classNames from "classnames";
import {LoaderButton} from "components/loader-button/LoaderButton";
import {Moment} from "moment/moment";
import moment from "moment-timezone";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DesktopDateTimePicker} from "@mui/x-date-pickers/DesktopDateTimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import classnames from "classnames";
import {ConfirmDialog} from "components/confirm-dialog/ConfirmDialog";
import Alert from "@mui/material/Alert";
import {DataType} from "../../typing/request";
import {PassCodesListAttributes} from "../../typing/dto";
import {iframeScrollIntoElement} from "utils/helpers";

interface PassCodesUpdateModalProps{
    userId: number;
    unitTimezone: string;
    passCodeId: number | null;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PassCodesUpdateModal: React.FC<PassCodesUpdateModalProps> = (
    {userId, unitTimezone, passCodeId, setShowModal}
) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    // redux state
    const passCodes: DataType<PassCodesListAttributes>[] | null = useSelector((state: RootState) => state.ticketEditPasscodes.passCodes);
    const isUpdating: boolean = useSelector((state: RootState) => state.ticketEditPasscodes.isUpdating);
    const isRemoving: boolean = useSelector((state: RootState) => state.ticketEditPasscodes.isRemoving);

    // vars
    const passCodeSelected = !isEmpty(passCodes) ? find(passCodes, ["id", passCodeId]) : null;
    const passCodeStartTime = isNil(passCodeSelected) ? moment() : moment.unix(Number(passCodeSelected.attributes.start_time));
    const passCodeEndTime = isNil(passCodeSelected) ? moment() : moment.unix(Number(passCodeSelected.attributes.end_time));
    const nowUnitTime: Moment = moment.tz(moment(), unitTimezone);
    const elementId = "passcodes-update-modal";

    // inner state
    const [startTime, setStarTime] = useState<Moment | null>(passCodeStartTime);
    const [endTime, setEndTime] = useState<Moment | null>(passCodeEndTime);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
    const [fromDatePickerOpen, setFromDatePickerOpen] = useState(false);
    const [toDatePickerOpen, setToDatePickerOpen] = useState(false);

    // Validators
    const isExpired: boolean = nowUnitTime > passCodeEndTime;
    const isInvalidDateRange: boolean = isNil(startTime) || isNil(endTime) || startTime === endTime;
    const isInvalid: boolean = isUpdating || isRemoving || isExpired;

    const openRemoveDialog = ():void => setShowRemoveConfirm(true);
    const closeRemoveDialog = ():void => setShowRemoveConfirm(false);

    /**
     * Updates Current Passcode 
     */
    const handleUpdate = async (): Promise<void> => {
        if (isNil(startTime) || isNil(endTime) || isNil(passCodeId)) return;  // early return if some field is null
        await dispatch(updatePassCode({
            userId,
            passCodeId,
            startTime: startTime?.unix().toString(),
            endTime: endTime.unix().toString()
        }));
        setShowModal(false);
    }

    /**
     * Removes/Deletes current passcode 
     */
    const handleRemove = async (): Promise<void> => {
        if (isNil(passCodeId)) return;  // early return if passcode id is null
        dispatch(removePassCode({userId, passCodeId}))
        closeRemoveDialog();
        setShowModal(false);
    }

    /**
     * Handles Starttime Change, if endtime is valid also updates Endtime 
     */
    const handleOnChangeStartTime = async (value: Moment | null) => {
        setStarTime(value);
        if ((isNil(endTime) || isNil(value)) || endTime >= value) return;
        setEndTime(value);
    }

    /**
     * Endtime stetter handler 
     */
    const handleOnChangeEndTime = async (value: Moment | null): Promise<void> => {
        setEndTime(value);
    }

    return (
        <Modal showModal={true} canExit={true} setShowModal={setShowModal} size="medium">
            <div className={styles.modal} data-testid="passcodes-update-modal" id={elementId}>
                {/* Cancel confirm dialog */}
                <ConfirmDialog
                    data-testid="passcode-remove-confirm-dialog"
                    title="Remove"
                    text="Are you sure you want to remove this passcode?"
                    visible={showRemoveConfirm}
                    onAccept={handleRemove}
                    onClose={closeRemoveDialog}
                />

                <h4 className="mb20">Edit Passcode</h4>

                <div><b>User: </b>{passCodeSelected?.attributes.first_name} {passCodeSelected?.attributes.last_name}</div>
                <div><b>Reference #: </b>{passCodeSelected?.attributes.transaction_id}</div>

                <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                    <div className={styles["datetime-selectors"]}>
                        <div className={styles.startdate}>
                            <label>START TIME (Local Unit Time)</label>
                            <div
                                data-testid="from-datetime-picker-trigger"
                                className={styles["hidden-trigger"]}
                                onClick={() => {
                                    setFromDatePickerOpen(true);
                                    iframeScrollIntoElement(elementId); // prevent out of focus if iframe mode
                                }
                            }></div>
                            <div className={styles['datetime-box']}>
                                <DesktopDateTimePicker
                                    open={fromDatePickerOpen}
                                    disabled={isExpired}
                                    defaultValue={startTime}
                                    timezone={unitTimezone}
                                    onChange={handleOnChangeStartTime}
                                    minDateTime={nowUnitTime}
                                    onClose={() => setFromDatePickerOpen(false)}
                                />
                            </div>
                        </div>

                        <div className={styles.enddate}>
                            <label>END TIME (Local Unit Time)</label>
                            <div 
                                data-testid="to-datetime-picker-trigger"
                                className={styles["hidden-trigger"]}
                                onClick={() => {
                                    setToDatePickerOpen(true);
                                    iframeScrollIntoElement(elementId); // prevent out of focus if iframe mode
                                }
                            }></div>
                            <div className={styles['datetime-box']}>
                                <DesktopDateTimePicker
                                    open={toDatePickerOpen}
                                    disabled={isExpired}
                                    value={endTime}
                                    timezone={unitTimezone}
                                    onChange={handleOnChangeEndTime}
                                    onClose={() => setToDatePickerOpen(false)}
                                    minDateTime={startTime ?? nowUnitTime}
                                />
                            </div>
                        </div>
                    </div>
                </LocalizationProvider>

                {/* Alert if date range is incorrect */}
                {isInvalidDateRange &&
                    <Alert severity="error">
                        Start Time and End Time cannot be the same.
                    </Alert>
                }

                <div className={styles["assignment-actions-box"]}>
                    <button className={classnames(styles["delete-btn"], "btn-chip")} onClick={openRemoveDialog}><IconTrash className="icon-bg"/></button>
                    <button
                        data-testid="cancel-passcode-update-button"
                        className={classNames("button button-primary", styles["cancel-btn"])}
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        id="passcode-update-button-submit"
                        variant="primary-inverse"
                        disabled={isInvalid}
                        onClick={handleUpdate}
                        className={styles["passcode-update-btn"]}
                    >
                        Apply
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
