import React from "react";
import styles from "./TicketEditDescription.module.scss";
import parse from 'html-react-parser';
import { InlineEdit } from "components/inline-edit/InlineEdit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { updateDescription, updateDetails } from "store/ticketEditSlice";
import DOMPurify from "dompurify";

interface TicketEditDescriptionProps {
    ticketId: number,
}

export const TicketEditDescription: React.FC<TicketEditDescriptionProps> = ({ticketId}: TicketEditDescriptionProps) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const description: string = useSelector((state: RootState) => (state.ticketEdit?.ticket?.description || ""));
    const details: string = useSelector((state: RootState) => (state.ticketEdit?.ticket?.template_body || ""));
    const safeDescription = description ? parse(DOMPurify.sanitize(description)) as string : "";
    const safeDetails = details ? parse(DOMPurify.sanitize(details)) as string : "";

    const saveDescription = async(description: string) => {
        dispatch(updateDescription({description, ticketId}));
    };
    const saveDetails = async(details: string) => {
        dispatch(updateDetails({details, ticketId}));
    };
    return ( 
        <section  className={styles.container}>
            <InlineEdit onAccept={saveDescription} maxLength={10000} title="Description">{safeDescription}</InlineEdit>
            <InlineEdit onAccept={saveDetails} maxLength={10000} title="Details">{safeDetails}</InlineEdit>
        </section>
    );
}
