/**
 * Set's up Environment names
 */
export class EnvMapper {
    // Environment Names
    static readonly LOCAL: string = "local";
    static readonly STAGING: string = "staging";
    static readonly PRODUCTION: string = "production";

    // Short Environment Names
    static readonly SHORT_LOCAL: string = "local";
    static readonly SHORT_STAGING: string = "stage";
    static readonly SHORT_PRODUCTION: string = "prod";

    // Hosts
    static readonly LOCAL_HOST_PREFIX: string = "localhost";
    static readonly STAGING_HOST_PREFIX: string = "vacasastage.services";
    static readonly PRODUCTION_HOST_PREFIX: string = "vacasa.services";

    /**
     * Get's environment name based on current host
     */
    public static environment(): string {
        const hostname = window.location.hostname;
        if (hostname.includes(this.LOCAL_HOST_PREFIX)) {
            return this.LOCAL;
        } else if (hostname.includes(this.STAGING_HOST_PREFIX)) {
            return this.STAGING;
        } else if (hostname.includes(this.PRODUCTION_HOST_PREFIX)) {
            return this.PRODUCTION;
        }

        return this.LOCAL;
    }

    /**
     * Get's short version of environment name
     */
    public static shortEnvironment(): string {
        const hostname = window.location.hostname;
        if (hostname.includes(this.LOCAL_HOST_PREFIX)) {
            return this.SHORT_LOCAL;
        } else if (hostname.includes(this.STAGING_HOST_PREFIX)) {
            return this.SHORT_STAGING;
        } else if (hostname.includes(this.PRODUCTION_HOST_PREFIX)) {
            return this.SHORT_PRODUCTION;
        }

        return this.SHORT_LOCAL;
    }

    /**
     * Check's if we are currently in local environment
     */
    public static isLocal() {
        return EnvMapper.environment() === EnvMapper.LOCAL;
    }

    /**
     * Check's if we are currently in staging environment
     */
    public static isStaging() {
        return EnvMapper.environment() === EnvMapper.STAGING;
    }

    /**
     * Check's if we are currently in production environment
     */
    public static isProduction() {
        return EnvMapper.environment() === EnvMapper.PRODUCTION;
    }
}

// Dynamically import `env.{environment}.ts`
const environmentVars = require(`./env/${EnvMapper.environment()}`);

export const env = {...process.env, ...environmentVars.default};
