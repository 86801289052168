// @ts-nocheck
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import {TicketView, TicketEdit, TicketSearch, TicketSearchSlim} from "views";
import {Configuration} from "core/configuration";
import {HeaderLayout} from "components/header-layout/HeaderLayout";

const Ticketing = (): JSX.Element => {

  return (
    <div id={Configuration.innerRootId} >
      <Router>
        <Routes>
          {/** Default Path redirect to Search */}
          <Route path="/" element={<Navigate to="ticket/search" />} />
          {/* Ticket Edit & View */}
          <Route element={<HeaderLayout />}>
            <Route path="ticket/:ticket_id/edit" element={<TicketEdit />} />
            <Route path="ticket/:ticket_id/view" element={<TicketView />} />
            <Route path="ticket/search" element={<TicketSearch />} />
          </Route>
          <Route path="ticket/search/simple" element={<TicketSearchSlim />} />
          {/** Default Path redirect to Search */}
          <Route path="*" element={<Navigate to="/" />} />

        </Routes>
      </Router>
    </div>
  );
};



export default Ticketing;
