/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import {TicketBox} from "components/ticket-box/TicketBox";
import {RootState} from "store/store";
import {fetchAssignedTo} from "store/ticketEditAssignmentSlice";
import {AdminService} from "services/AdminService";
import {useDispatch, useSelector} from "react-redux";
import {UserConnectAttributes} from "typing/dto";
import {isEmpty} from "lodash";
import {TicketEditAssignmentModal} from "components/ticket-edit-assignment-modal/TicketEditAssignmentModal";
import {isNil} from "lodash";
import {Spinner} from "components/spinner/Spinner";
import {ThunkDispatch} from "@reduxjs/toolkit";
import styles from "./TicketEditAssignment.module.scss";
import {useModalScroll} from "hooks/useModalScroll";

interface TicketEditAssignmentProps {
    userId?: number;
    ticketId: number;
    isEditable: boolean;
}

export const TicketEditAssignment: React.FC<TicketEditAssignmentProps> = ({userId, ticketId, isEditable}) => {
    const isLoadingFetch = useSelector((state: RootState) => state.ticketEditAssignment.isLoadingFetch);
    const assignedTo = useSelector((state: RootState) => state.ticketEditAssignment.assignedTo);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [isModalOpen, setIsModalOpen] = useModalScroll(false);

    useEffect(() => {
        if (!assignedTo && userId) dispatch(fetchAssignedTo(userId))
    }, [userId, assignedTo, dispatch]); 

    const DisplayAssignedUser = ({user}: {user: UserConnectAttributes | null}) => (
        <>
            {
                !isEmpty(user) ? (
                    <>
                        <a
                            href={AdminService.getUserTicketsUrl(user.id)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {user.first_name} {user.last_name}
                        </a> ({user.title})
                    </>
                ) : (
                    <span data-testid="ticket-assignment-not-found">Not found</span>
                )
            }
        </>
    )

    return (
        <>
            <TicketBox>
                {isModalOpen && !isNil(ticketId) &&
                    <TicketEditAssignmentModal ticketId={Number(ticketId)} setIsModalOpen={setIsModalOpen} />
                }

                <div className="box-link">
                    <button
                        className="btn-link"
                        data-testid="reassign-modal-opener"
                        onClick={() =>setIsModalOpen(true)}
                        disabled={!isEditable}
                    >
                        Reassign
                    </button>
                </div>
                <div className="box-title">Assigned:</div>
                <div className="box-value">
                    {
                        isLoadingFetch ? (
                            <div className={styles.spinner}><Spinner /></div>) : (
                            <DisplayAssignedUser user={assignedTo}/>
                        )
                    }
                </div>
            </TicketBox>
        </>
    );
}
