import debounce from "lodash/debounce";
import {useCallback} from "react";

function useCallbackWithDebounce(callback: Function, delay: number = 700) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        debounce((args) => callback(args), delay),
        []
    );
}

export default useCallbackWithDebounce;
