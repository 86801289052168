import {HistoryUpdatedFieldEnum} from "appConstants";
import {TicketHistoryV1Attributes} from "typing/dto";

export class TicketHistoryEntity {
    private _ticketHistory: TicketHistoryV1Attributes;

    public static compareHistoryStatus(value: string, historyFields: HistoryUpdatedFieldEnum[]): boolean {
        return value ? historyFields.map((value) => String(value).toLowerCase()).includes(value.toLowerCase()) : false;
    }

    public static isAllowedValue(field: string): boolean {
        return TicketHistoryEntity.compareHistoryStatus(field, [...Object.values(HistoryUpdatedFieldEnum)]);
    }

    constructor(ticketHistory: TicketHistoryV1Attributes) {
        this._ticketHistory = ticketHistory;
    }

    public get isAssignedTo(): boolean {
        return TicketHistoryEntity.compareHistoryStatus(this.updatedField, [
            HistoryUpdatedFieldEnum.ASSIGNED_TO,
            HistoryUpdatedFieldEnum.ASSIGNED_TO_ID,
        ]);
    }

    public get isSeverity(): boolean {
        return TicketHistoryEntity.compareHistoryStatus(this.updatedField, [HistoryUpdatedFieldEnum.SEVERITY]);
    }

    public get isVisibility(): boolean {
        return TicketHistoryEntity.compareHistoryStatus(this.updatedField, [HistoryUpdatedFieldEnum.VISIBILITY]);
    }

    public isValid() {
        return TicketHistoryEntity.isAllowedValue(this.updatedField) && !!this.updatedValue && String(this.updatedValue).indexOf("null") === -1; // TODO: Remove it after patch
    }

    public get updatedValue(): string | number | null {
        return this.ticketHistory.updated_value;
    }

    public get text(): string | null {
        return this.ticketHistory.text;
    }

    public get updatedField(): string {
        return this.ticketHistory.updated_field;
    }

    public get ticketHistory(): TicketHistoryV1Attributes {
        return this._ticketHistory;
    }

    public get assignee(): number | null {
        return this.isAssignedTo ? Number(this.ticketHistory.updated_value) : null;
    }

    public get creationDate(): string {
        return this.ticketHistory.created_at;
    }
}
