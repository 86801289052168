import {ALERT_TEMPLATE_ERROR, ALERT_TEMPLATE_SUCCESS, UNKNOWN_ERROR} from "appConstants";
import {EventLogger} from "core/eventLogger";
import {replace} from "lodash";
import {enqueueSnackbar, SnackbarOrigin} from "notistack";
import {GenericSnackbarMessage} from "typing/message";

export const anchorOrigin: SnackbarOrigin = {vertical: "top", horizontal: "center"};

/**
 * Gets a success or fail message based on a template
 * @param
 * @returns
 */
export const getSnackbarMessage = ({logInfo, error}: GenericSnackbarMessage): string => {
    const {target = "ticket", action = "updated", plural} = logInfo;
    const template = error ? ALERT_TEMPLATE_ERROR : ALERT_TEMPLATE_SUCCESS;
    return replacer(template, [target, plural ? "have" : "has", action, error?.message || ""]);
};
export function replacer(template: string, args: string[]) {
    return args.reduce((previousValue, currentValue, currentIndex) => replace(previousValue, `{${currentIndex}}`, currentValue), template);
}

/**
 * Creates an error message
 * @param
 */
export const enqueueError = ({logInfo, error}: GenericSnackbarMessage) => {
    if (!(error instanceof Error)) {
        console.error(error);
        error = new Error(UNKNOWN_ERROR);
    }
    enqueueSnackbar(getSnackbarMessage({logInfo, error}), {variant: "error", anchorOrigin});
};

/**
 * Creates a success error with log if needed
 * @param
 * @returns
 */
export const enqueueSuccess = ({logInfo, data}: GenericSnackbarMessage) => {
    const {logEvent} = logInfo;
    enqueueSnackbar(getSnackbarMessage({logInfo}), {variant: "success", anchorOrigin});
    if (logEvent) EventLogger.emitSuccessEvent({logInfo, data});
};

export const getErrorMessage = (error: any): string => {
    if (error instanceof Error) {
        return error.message;
    } else if (typeof error === "string") {
        return error;
    }
    return UNKNOWN_ERROR;
};
