import React, { useEffect, useState } from "react";
import {TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import {fetchDeviceGatewaysAndPassCodes, revealPassCode} from "store/ticketEditPassCodesSlice";
import {useSelector, useDispatch } from "react-redux";
import {Spinner} from "components/spinner/Spinner";
import {ThunkDispatch } from "@reduxjs/toolkit";
import {RootState} from "store/store";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import styles from "./TicketEditPassCodes.module.scss";
import moment from "moment-timezone";
import {ReactComponent as IconCircleCheck} from "assets/icons/check-circle.svg";
import {ReactComponent as IconPencilEdit} from "assets/icons/pencil-edit.svg";
import {ReactComponent as IconCircleClose} from "assets/icons/close-circle.svg";
import classnames from "classnames";
import {useModalScroll} from "hooks/useModalScroll";
import {PassCodesAddModal} from "components/ticket-edit-passcodes-add-modal/PassCodesAddModal";
import {PassCodesUpdateModal} from "components/ticket-edit-passcodes-update-modal/PassCodesUpdateModal";
import Tooltip from '@mui/material/Tooltip';

interface TicketEditPasscodesProps {
    userId: number;
    ticketId: number;
    unitId: number;
    unitTimezone: string | undefined;
}

export const TicketEditPassCodes: React.FC<TicketEditPasscodesProps> = ({userId, ticketId, unitId, unitTimezone}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isFetching = useSelector((state: RootState) => state.ticketEditPasscodes.isFetching);
    const hasActiveDevices = useSelector((state: RootState) => state.ticketEditPasscodes.hasActiveDevices);
    const passCodes = useSelector((state: RootState) => state.ticketEditPasscodes.passCodes);
    const accessCodes = useSelector((state: RootState) => state.ticketEditPasscodes.accessCodes);

    const [showAddModal, setShowAddModal] = useModalScroll(false);
    const [showUpdateModal, setShowUpdateModal] = useModalScroll(false);
    const [passCodeToUpdate, setPassCodeToUpdate] = useState<number | null>(null);

    const nowUnitTime = !isNil(unitTimezone) ? moment.tz(moment(), unitTimezone) : null;

    useEffect(() => {
        dispatch(fetchDeviceGatewaysAndPassCodes({ticketId, unitId}));
    }, [dispatch, ticketId, unitId]);

    // handlers
    const handleUpdate = async (passCodeId: number) => {
        setPassCodeToUpdate(passCodeId);
        setShowUpdateModal(true);
    }

    const handleReveal = async (passCodeId: number) => {
        dispatch(revealPassCode({userId, passCodeId}));
    }

    const TicketEditPasscodesHeader = ({ options = true }) => (
        <div>
            <h2>Passcodes</h2>
            <button
                data-testid="passcode-add-btn"
                onClick={() => setShowAddModal(true)}
                className="btn-link"
                disabled={!options}
            >
                Add Passcode
            </button>
        </div>
    )

    // Display loader until
    if (isFetching) {
        return (
            <TicketBoxCollapsable
                header={<TicketEditPasscodesHeader options={false} />}>
                <Spinner/>
            </TicketBoxCollapsable>
        );
    }

    return (
        <TicketBoxCollapsable header={<TicketEditPasscodesHeader options={!!hasActiveDevices} />} >
            {showAddModal && !isNil(unitTimezone) && <PassCodesAddModal userId={userId} unitTimezone={unitTimezone} setShowModal={setShowAddModal} ticketId={ticketId} />}
            {showUpdateModal && !isNil(unitTimezone) && <PassCodesUpdateModal userId={userId} unitTimezone={unitTimezone} passCodeId={passCodeToUpdate} setShowModal={setShowUpdateModal} />}
            <div data-testid="passcodes-content-box" className={styles.content}>
            {
                hasActiveDevices === false ?
                    <>No active devices found.</> :
                    isEmpty(passCodes) ?
                        <>No passcodes found.</> :
                        isNil(unitTimezone) || isNil(nowUnitTime) ?
                            <>No unit timezone found in unit.</> :
                            <>
                                {!isNil(passCodes) && passCodes.map((passCode, index) => {
                                    const accessCode = accessCodes[passCode.id];
                                    const startUnitTime = moment.tz(moment.unix(Number(passCode.attributes.start_time)), unitTimezone);
                                    const endUnitTime = moment.tz(moment.unix(Number(passCode.attributes.end_time)), unitTimezone);
                                    const isExpired = nowUnitTime > endUnitTime;
                                    return <div data-testid="passcode-item" key={index}>
                                        {index !== 0 && (<hr className={styles.hr} />)}
                                        <div>
                                            <div className={styles["passcode-line"]}>
                                                <div>
                                                    <b>Smart Lock</b> {
                                                    accessCode.isLoading ?
                                                            <span className={styles["access-code-loading"]}><Spinner /></span> :
                                                            isNil(accessCode.code) ?
                                                                <button
                                                                    data-testid="passcode-reveal-btn"
                                                                    onClick={() => handleReveal(passCode.id)}
                                                                    className="btn-link"
                                                                >
                                                                    Reveal Passcode
                                                                </button> :
                                                                <i data-testid="access-code">{accessCode.code}</i>
                                                    }
                                                </div>
                                                <div data-testid="passcode-check-circle" className={classnames(styles["right-icon-box"])}>
                                                    {isExpired ? (
                                                        <span className={styles["cross-icon"]}>
                                                            <Tooltip title={`Status Expired`} ><IconCircleClose /></Tooltip>
                                                        </span>
                                                        ) : (
                                                        <span className={styles["check-icon"]}>
                                                            <Tooltip title={`Status Active (${nowUnitTime > startUnitTime ? "ongoing" : "upcoming"})`} >
                                                                <IconCircleCheck />
                                                            </Tooltip>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles["passcode-line"]}>
                                                <div>
                                                    <b>User </b>
                                                    <span data-testid="passcode-user-full-name">
                                                        {passCode.attributes.first_name} {passCode.attributes.last_name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={styles["passcode-line"]}>
                                                <div>
                                                    <b>Date Range</b> {startUnitTime.format("MM/DD/YY")} - {endUnitTime.format("MM/DD/YY")}
                                                </div>
                                                <div className={styles["right-icon-box"]}>
                                                    <button data-testid="passcode-edit-btn" className="btn-chip" onClick={() => handleUpdate(passCode.id)}>
                                                        <IconPencilEdit className="icon-bg" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </>
            }
            </div>
        </TicketBoxCollapsable>
    );
}
