import {TicketV1Attributes} from "typing/dto";

export class TicketEntity {
    private ticket: TicketV1Attributes;

    // Hardcoded List of Requested By Alternatives
    static readonly requestedByOptions = {
        "1": "Owner",
        "2": "Guest",
        "3": "Staff",
    };

    // Hardcoded list of severity options
    static readonly severityOptions = [
        {name: "Urgent", id: 1},
        {name: "Today (High)", id: 2},
        {name: "Prior To Next Guest", id: 3},
        {name: "Medium", id: 4},
        {name: "Low", id: 5},
        {name: "Planned", id: 6},
    ];

    // Hardcoded mapping for Verbose Assignment Reasons
    static readonly reasonForAssignment = {
        cross_coverage: "Cross Coverage",
        coverage_partner: "Coverage Partner",
        manager: "Manager",
        manual: "Manually Assigned",
        standard_availability: "Standard Availability",
        zone_disposition_default: "Disposition Default",
        non_urgent_unit_manager_default: "Non-Urgent Unit Manager Default",
        non_urgent_zone_disposition_default: "Non-Urgent Disposition Default",
        no_unit_manager: "No Unit Manager or Disposition Default",
    };

    // Hardcoded mapping for days of recurrence
    static readonly frequencyOfRecurrence = {
        "1": "Daily",
        "7": "Weekly",
        "30": "Monthly (Every 30 Days)",
        "90": "Quarterly (Every 90 Days)",
        "180": "Semi-Annually (Every 180 Days)",
        "365": "Annually",
    };

    // Hardcoded Mappings for Reopen types
    static readonly frequencyReopenTypes = {
        last_open_date: "Created Date / Last Open Date",
        last_due_date: "Last Due Date",
        last_completed_date: "Completed Date / Closed Date",
        frequency_start_date_cycle: "Custom",
    };

    static readonly frequencyReopenAfterDuedate = {
        "30": "30 Days After Reopen Date",
        "21": "3 Weeks After Reopen Date",
    };

    constructor(ticket: TicketV1Attributes) {
        this.ticket = ticket;
    }

    /**
     * Checks if a Ticket is in Open state
     */
    isOpen(): boolean {
        return !this.ticket.void && !this.ticket.completed_date;
    }

    /**
     * Get's who Requested the Ticket
     */
    getRequestedBy(): {id: number; name: string} {
        if (!this.ticket.requested_by || !Object.keys(TicketEntity.requestedByOptions).includes(this.ticket.requested_by.toString()))
            return {id: 0, name: ""};

        return {
            id: this.ticket.requested_by,
            // @ts-ignore
            name: TicketEntity.requestedByOptions[this.ticket.requested_by.toString()],
        };
    }

    /**
     * Gets Verbose Reason For Assignment
     */
    getReasonForAssignment(): string {
        if (!Object.keys(TicketEntity.reasonForAssignment).includes(this.ticket.reason_for_assignment)) return "";
        // @ts-ignore
        return TicketEntity.reasonForAssignment[this.ticket.reason_for_assignment];
    }

    /**
     * Gets Verbose Frequency Of Recurrence
     */
    getFrequencyOfRecurrence(): string {
        const frequency = this.ticket.frequency ? this.ticket.frequency.toString() : "0";

        // @ts-ignore
        return TicketEntity.frequencyOfRecurrence[frequency];
    }

    /**
     * Check if this a recurrent ticket
     */
    static isRecurrent(frequency: number | null): boolean {
        return Number(frequency) > 0;
    }
}
