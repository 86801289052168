import React from "react";
import { Alert, AlertColor } from "@mui/material";

export interface AlertMessageParams {
    text?: string;
    severity?: AlertColor;
    visible?: boolean;
    variant?: "outlined" | "standard" | "filled";
}

interface AlertMessageProps {
    params: AlertMessageParams,
    onClose?: () => any;
}

export const AlertMessage: React.FC<AlertMessageProps> = ({params, onClose}: AlertMessageProps) => {
    let {text, severity, visible, variant} = params;
    visible = !text ? false : visible;
    severity = severity ?? 'error';
    variant = variant ?? "outlined";
    return (
        <div>
        { visible && 
            <Alert data-testid="alert-message" variant={variant} severity={severity} onClose={onClose}>
                {text}
            </Alert>
        }
        </div>
    );
}
