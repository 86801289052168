/**
 * Collection of Tags to be added in Logs/Metrics
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    division: "platform",
    project: "platform-tools",
    repository: "ticketing-admin-web",
    team: "shaky-tamers",
    service: "ticketing-admin-web",
};
