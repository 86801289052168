import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EVENT_LOG_DATA} from "appConstants";
import {TicketingService} from "services/TicketingService";
import {TicketTimesV1Attributes} from "typing/dto";
import {enqueueError, enqueueSuccess, getErrorMessage} from "utils/message";

const {TICKET_TIME_ADD} = EVENT_LOG_DATA;

// Types
interface IFetchTimesThunk {
    ticketId: number;
    userId: number;
}

interface IAddTimesThunk {
    ticketId: number;
    userId: number;
    note: string;
    fromTime: any;
    toTime: any;
    external: 0 | 1;
}

// Service Singletons
const ticketingService = TicketingService.getInstance();

export const fetchTimes = createAsyncThunk<any, IFetchTimesThunk, {rejectValue: string}>(
    "ticketEditTimes/fetchTimes",
    async ({ticketId, userId}, {rejectWithValue}) => {
        try {
            return ticketingService.getTimes(ticketId, userId);
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const addTimes = createAsyncThunk<any, IAddTimesThunk, {rejectValue: string}>(
    "ticketEditTimes/addTimes",
    async ({ticketId, userId, note, fromTime, toTime, external}, {rejectWithValue}) => {
        try {
            return ticketingService.addTimes(ticketId, userId, note, fromTime, toTime, external);
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export interface ITicketEditTimesState {
    isLoadingFetch: boolean;
    isLoadingAdd: boolean;
    times: Array<TicketTimesV1Attributes> | null;
}

export const initialState: ITicketEditTimesState = {
    times: null,
    isLoadingFetch: true,
    isLoadingAdd: false,
};

const ticketEditTimesSlice = createSlice({
    name: "ticketEditTimes",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTimes.pending, (state: ITicketEditTimesState) => {
                state.isLoadingFetch = true;
                state.times = [];
            })
            .addCase(fetchTimes.fulfilled, (state: ITicketEditTimesState, {payload}: {payload: TicketTimesV1Attributes[]}) => {
                state.isLoadingFetch = false;
                state.times = payload;
            })
            .addCase(fetchTimes.rejected, (state: ITicketEditTimesState) => {
                state.isLoadingFetch = false;
                state.times = [];
            })
            .addCase(addTimes.pending, (state: ITicketEditTimesState) => {
                state.isLoadingAdd = true;
            })
            .addCase(addTimes.fulfilled, (state: ITicketEditTimesState, {payload}: {payload: TicketTimesV1Attributes}) => {
                state.isLoadingAdd = false;
                enqueueSuccess({logInfo: TICKET_TIME_ADD, data: {payload}});
            })
            .addCase(addTimes.rejected, (state: ITicketEditTimesState, {payload}: {payload: any}) => {
                state.isLoadingAdd = false;
                enqueueError({logInfo: TICKET_TIME_ADD, error: Error(payload)});
            });
    },
});

export default ticketEditTimesSlice.reducer;
