import React, {useEffect} from "react";
import classNames from 'classnames';
import styles from "./TicketSearchSlim.module.scss";
import { closeAlert, fetchUsers, fetchTickets   } from "store/ticketSearchSlice";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "components/spinner/Spinner";
import { GetTicketsResponse, PageQueryParams, SearchOrderByQueryParams, SearchSelectedFilterValues, TicketingServiceFilter } from "typing/request";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AlertMessage, AlertMessageParams } from "components/alert-message/AlertMessage";
import { filtersToParams, orderByToParams } from "utils/list-params";
import { TicketSearchFiltersSlim } from "components/ticket-search-filters-slim/TicketSearchFiltersSlim";
import { TicketSearchTableSlim } from "components/ticket-search-table-slim/TicketSearchTableSlim";
import { TicketInclude } from "appConstants";
import useURLParams from "hooks/useURLParams";
import { TicketSearchSkeleton } from "components/ticket-search-skeleton/TicketSearchSkeleton";

export const TicketSearchSlim: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { getParamsByCategory } = useURLParams();

    const tickets = useSelector((state: RootState) => state.ticketSearch.tickets);
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );
    const orderBy: SearchOrderByQueryParams = useSelector((state: RootState) => state.ticketSearch.orderBy)
    const page: PageQueryParams = useSelector((state: RootState) => state.ticketSearch.page);
    const isLoading: boolean = useSelector((state: RootState) => state.ticketSearch.isLoading);
    const reload: boolean = useSelector((state: RootState) => state.ticketSearch.reload);
    const alertMessageParams: AlertMessageParams = useSelector((state: RootState) => state.ticketSearch.alertMessageParams);
    
    // Extract filter parameters from the URL query
    const filterQueryParams: TicketingServiceFilter  = getParamsByCategory('filter');

    const fetchData = async (filterQueryParams: TicketingServiceFilter) => {
        const result = await dispatch(fetchTickets({
            page,
            filter: {...filtersToParams(selectedFilterValues), unit_id: filterQueryParams.unit_id},
            sort: orderByToParams(orderBy),
            include: TicketInclude.COMMENTS
        }));

        const listResponse = result.payload as GetTicketsResponse;
        if (!listResponse || !listResponse?.data?.length) return;
        
        // Adds all needed users
        const assignedToList = listResponse.data.map(ticket => ticket.attributes.assigned_to);
        const commentAuthorList = listResponse.data.map(ticket => ticket.includedMap?.comments[0]?.attributes.user_id);
        await dispatch(
            fetchUsers(
                [
                    ...assignedToList,
                    ...commentAuthorList
                ]
                .filter(v => !!v)
                .join(',')
            )
        );  
    };

    useEffect(() => {
        fetchData(filterQueryParams);
    },[reload]); // eslint-disable-line react-hooks/exhaustive-deps

    // Display loader until
    if ((!tickets.length && !Object.keys(selectedFilterValues).length) && isLoading) {
        return <TicketSearchSkeleton />;
    }

    return (
        <div className={classNames(styles.container,"max-width")}>
            {isLoading && <div className={styles.spinner}>
                <Spinner />
            </div>} 
            <TicketSearchFiltersSlim filterQueryParams={filterQueryParams}/>
            <AlertMessage params={alertMessageParams} onClose={() => dispatch(closeAlert())}/>
            <TicketSearchTableSlim/>
        </div>
    );
}
