/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import { TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import { RootState } from "store/store";
import { AdminService } from "services/AdminService";
import { fetchTimes } from "store/ticketEditTimesSlice";
import { useSelector, useDispatch} from "react-redux";
import { Spinner } from "components/spinner/Spinner";
import { dateAndTime, dateOnly } from "utils/date";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {TicketTimesV1Attributes, UserConnectAttributes} from "typing/dto";
import { TicketEditTimesModal } from "components/ticket-edit-times-modal/TicketEditTimesModal";
import styles from "./TicketEditTimes.module.scss";
import moment from "moment";
import { useModalScroll } from "hooks/useModalScroll";
import TicketingService from "services/TicketingService";
import isNil from "lodash/isNil";

interface ITicketEditHeaderProps {
    ticketId: number;
    started: string | null;
    completed: string | null;
    void: string | null;
    due: string;
    created: string;
    creator?: UserConnectAttributes | null;
}



export const TicketEditTimes: React.FC<ITicketEditHeaderProps> = (props) => {
    const currentUserId = TicketingService.getInstance().currentUserId;
    const isLoadingFetch = useSelector((state: RootState) => state.ticketEditTimes.isLoadingFetch);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const times = useSelector((state: RootState) => state.ticketEditTimes.times);
    const [isModalOpen, setIsModalOpen] = useModalScroll(false);

    // Header Component
    const TicketEditTimesHeader = ({options = true}: {options?: boolean}) => (
        <div>
            <h2>Times</h2>
                <button
                    className="btn-link"
                    data-testid="add-times-modal-opener"
                    onClick={() => setIsModalOpen(true)}
                    disabled={!options}
                >
                    Add Time Entry
                </button>
        </div>
    );

    useEffect(() => {
        dispatch(fetchTimes({ticketId: props.ticketId, userId: currentUserId}));
    }, [dispatch, props.ticketId, currentUserId]);
    

    // Display loader until
    if (isLoadingFetch) {
        return (
            <TicketBoxCollapsable
                header={<TicketEditTimesHeader options={false} />}>
                <Spinner/>
            </TicketBoxCollapsable>
        );
    }

    return (
        <>
            {/* Modal-force unmount if showModal === false */}
            {isModalOpen &&
                <TicketEditTimesModal
                    ticketId={props.ticketId}
                    setIsModalOpen={setIsModalOpen}
                    />
            }
            
            {/* Times Display **/}
            <TicketBoxCollapsable header={<TicketEditTimesHeader />} style={{maxHeight: "fit-content"}}>
                
                <b>Created:</b> {dateAndTime(props.created)}
                
                {/* Who Created the Ticket */}
                {props.creator && (
                    <>
                        <span style={{marginLeft: 5}}>
                            by <a
                                href={AdminService.getUserTicketsUrl(props.creator.id)}
                                target="_blank"
                                rel="noreferrer"
                                data-testid="creator"
                                >{props.creator.first_name} {props.creator.last_name}
                                </a>
                        </span><br/>
                    </>
                )}
                
                {/* Ticket State Dates */}
                {props.started && (<p data-testid="date-started"><b>Started:</b> {dateAndTime(props.started)}<br/></p>)}
                {props.due && (<p data-testid="date-due"><b>Due:</b> {dateOnly(props.due)}<br/></p>)}
                {props.completed && (<p data-testid="date-completed"><b>Completed:</b> {dateAndTime(props.completed)}<br/></p>)}
                {props.void && (<p data-testid="date-canceled"><b>Canceled:</b> {dateAndTime(props.void)}<br/></p>)}

                {/* Render Times List */}
                <div>
                    <b>Time Entries:</b>
                </div>
                {!isNil(times) && times.length ? times.map( (time: TicketTimesV1Attributes, idx:number) => (
                    <div key={idx} className={styles.time} data-testid="time-entry">
                        {moment(time.from).format("MM/DD hh:mma")} - {moment(time.to).format("hh:mma")}<br/>
                        <div className={styles.comment}>
                            {time.note}
                        </div>
                    </div>
                )) : <p data-testid="time-entry-not-found">Not found</p>}
            </TicketBoxCollapsable>
        </>
    );
}
