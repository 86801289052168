import React from "react";
import styles from "./TicketBox.module.scss";

interface TicketBoxProps {
    children: React.ReactNode
}

export const TicketBox: React.FC<TicketBoxProps> = ({children}) => {
    return ( 
        <div className={styles.box}>
            {children}
        </div>
    );
}