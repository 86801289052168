import React, { useState } from "react";
import styles from "./InlineEdit.module.scss";
import { ReactComponent as IconPencilEdit } from "assets/icons/pencil-edit.svg"
import { ReactComponent as IconClose} from "assets/icons/close.svg"
import { ReactComponent as IconCheck } from "assets/icons/check.svg"
import { datadogLogs } from "@datadog/browser-logs";
import DOMPurify from 'dompurify';
import { NOT_FOUND_TEXT } from "appConstants";

interface IInlineEditProps extends Partial<React.FormEvent<HTMLDivElement>> {
    children: string;
    title: string;
    onAccept: (text: string) => Promise<any>;
    maxLength?: number;
};

export const InlineEdit: React.FC<IInlineEditProps>= ({children, title, onAccept, maxLength = 250, ...rest}: IInlineEditProps) => {
    const [isEdit, setIsEdit] = useState(false);
    const [text, setText] = useState(children);
    const [isLoading, setIsLoading] = useState(false);
    const handleAccept = async() => {
        try {
            setIsLoading(true);
            await onAccept(text);
            handleCancel();
        } catch(e) {
            if (!(e instanceof Error)) throw e;
            datadogLogs.logger.error("InlineEdit.handleAccept", {}, e);
        }
        setIsLoading(false);
    };

    const handleCancel = () => {
        setIsEdit(!isEdit);
    };

    const handleChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(DOMPurify.sanitize(event.target.value));
    };

    return (
        <div className={styles.container}>
            <h2>{title}
                {!isEdit &&
                    <button data-testid="inline-edit-edit-btn" className="btn-chip" onClick={() => setIsEdit(!isEdit)}>
                        <IconPencilEdit className="icon-bg" />
                    </button>
                }
            </h2>
            {isEdit ?
                <div>
                    <textarea maxLength={maxLength} disabled={isLoading} defaultValue={children} className={styles.input} onChange={handleChange} {...rest}></textarea>
                    <div className={styles.actions}>
                        <span>
                            {text.length} / {maxLength}
                        </span>
                        <button data-testid="inline-edit-cancel-btn" disabled={isLoading} className="btn-chip" onClick={handleCancel}>
                            <IconClose className="icon-bg" />
                        </button>
                        <button data-testid="inline-edit-accept-btn" disabled={isLoading} className="btn-chip" onClick={handleAccept}>
                            <IconCheck className="icon-bg" />
                        </button>
                    </div>
                </div>:
                <p>{children || NOT_FOUND_TEXT}</p>
            }
        </div>
    );
};
