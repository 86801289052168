import React, { useEffect } from "react";
import styles from "./TicketEditHistory.module.scss"
import isEmpty from "lodash/isEmpty";
import { RootState } from "store/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector, useDispatch} from "react-redux";
import { Spinner } from "components/spinner/Spinner";
import { HistoryFilterOption, fetchHistory, fetchOwner, setFilter, fetchTaggedUsers } from "store/ticketEditHistorySlice";
import { TicketEditHistoryHeader } from "components/ticket-edit-history-filter/TicketEditHistoryFilter";
import { TicketEditHistoryList } from "components/ticket-edit-history-list/TicketEditHistoryList";
import { TicketCommentV1Attributes } from "typing/dto";
import { HistoryFilterOptionEnum, TaggedUsernameRegex } from "appConstants";

interface ITicketEditHistoryProps {
    ticketId: number;
    unitId: number;
}

export const TicketEditHistory: React.FC<ITicketEditHistoryProps> = ({ticketId, unitId}: ITicketEditHistoryProps) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isLoadingFetch = useSelector((state: RootState) => state.ticketEditHistory.isLoadingFetch);
    const history = useSelector((state: RootState) => state.ticketEditHistory.history);
    const comments = useSelector((state: RootState) => state.ticketEditHistory.comments);
    const filter = useSelector((state: RootState) => state.ticketEditHistory.filter);
    const users = useSelector((state: RootState) => state.ticketEditHistory.users);
    const owners = useSelector((state: RootState) => state.ticketEditHistory.owners);

    const isActivityFilterOn = filter.includes(HistoryFilterOptionEnum.ACTIVITY);
    const isStaffOnlyFilterOn = filter.includes(HistoryFilterOptionEnum.STAFF_ONLY);
    const isStaffOwnerFilterOn = filter.includes(HistoryFilterOptionEnum.STAFF_OWNER);

    const commentFilter = (comment: TicketCommentV1Attributes) => !filter.length ||
        (isStaffOnlyFilterOn && comment.external === 0) ||
        (isStaffOwnerFilterOn && comment.external === 1);
    const filteredComments = comments.filter(comment => commentFilter(comment));
    const filteredHistory = !filter.length || isActivityFilterOn ? history : [];
    const itemsCount = history.length + comments.length;
    const filteredItemsCount = filteredHistory.length + filteredComments.length;

    useEffect(() => {
        dispatch(fetchHistory(ticketId));
        dispatch(fetchOwner(unitId));
    }, [ticketId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const userTagsSet = comments.reduce((acc, comment) => {
            const note = comment.note || '';
            const matches = [...note.matchAll(new RegExp(TaggedUsernameRegex.AT_EXCLUDED, 'g'))].map(match => match[1]);
            matches.forEach(username => acc.add(username));
            return acc;
        }, new Set<string>());

        if (!isEmpty(comments) && !isEmpty(userTagsSet)) {
            dispatch(fetchTaggedUsers([...userTagsSet].join(",")))
        };
    }, [comments]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateFilter = (options: HistoryFilterOption[]) => {
        dispatch(setFilter(options));
    };

    return ( 
        <section className={styles.content}>
            {isLoadingFetch ?
                <Spinner/>
                :
                <>
                    <h2>History ({ filter.length ? filteredItemsCount : itemsCount })</h2>
                    <TicketEditHistoryHeader onUpdate={updateFilter}/>
                    <TicketEditHistoryList history={filteredHistory} comments={filteredComments} users={users} owners={owners}/>
                </>
            }
        </section>
    );
}
