import React from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Modal } from '@vacasa/react-components-lib';
import { deleteAttachment } from "store/ticketEditAttachmentsSlice";
import { LoaderButton } from "components/loader-button/LoaderButton";
import styles from "./DeleteAttachmentModal.module.scss"

interface DeleteAttachmentModalProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedAttachment: Partial<{
        ticketId: number;
        filename: string;
    }>;
}

export const DeleteAttachmentModal: React.FC<DeleteAttachmentModalProps> = ({ setShowModal, selectedAttachment }) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const handleDelete = async () => {
        await dispatch(deleteAttachment(selectedAttachment));
        setShowModal(false);
    }

    return (
        <Modal
            showModal={true}
            setShowModal={() => setShowModal(false)}
            size="large"
        >
            <div className="modal">
                <h1>Delete Attachment</h1>
                <div>
                    <p>Delete the following attachment? You will not able to recover it.</p>
                    <div className={styles.deleteAttachmentList}>
                        <ul>
                            <li>{selectedAttachment.filename}</li>
                        </ul>
                    </div>
                </div>
                <div className="modal-actions">
                    <button
                        className="button button-primary"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        variant="primary-inverse"
                        onClick={handleDelete}
                        disabled={!Object.entries(selectedAttachment).length}
                    >
                        Delete
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
