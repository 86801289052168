import React from "react";
import { SnackbarContent, CustomContentProps, useSnackbar } from 'notistack';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import styles from "./Toast.module.scss"

interface ToastProps extends CustomContentProps {
  severity: Color;
}


export const Toast = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref} role="alert" className={styles["snackbar-content"]}>
      <MuiAlert elevation={6} variant="outlined" severity={props.severity} onClose={() => closeSnackbar(props.id)}>
        {props.message}
      </MuiAlert>
    </SnackbarContent>
  )
});

export const ToastSuccess = React.forwardRef<HTMLDivElement, Omit<ToastProps, 'severity'>>((props, ref) => (
  <Toast {...props} ref={ref} severity="success" />
));

export const ToastError = React.forwardRef<HTMLDivElement, Omit<ToastProps, 'severity'>>((props, ref) => (
  <Toast {...props} ref={ref} severity="error" />
));
