import {UserConnectAttributes} from "typing/dto";

export class ConnectUserEntity {
    private _userConnect: UserConnectAttributes;

    constructor(userConnect: UserConnectAttributes) {
        this._userConnect = userConnect;
    }

    public get fullName() {
        return [this.userConnect.first_name, this.userConnect.last_name].filter((v) => !!v).join(" ");
    }

    public get userConnect(): UserConnectAttributes {
        return this._userConnect;
    }
}
