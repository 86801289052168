import React from "react";
import { Tooltip, TooltipProps, Typography} from "@material-ui/core";
import styles from "./ExtraTextTooltip.module.scss";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from "@mui/material";

interface IExtraTextTooltipProps {
    text?: string;
    altText?: string | React.ReactNode; 
    maxLength?: number;
    placement?: string;
    children?: React.ReactNode;
}

export const ExtraTextTooltip: React.FC<IExtraTextTooltipProps> = ({ text, altText, maxLength = 5, placement = "bottom", children}: IExtraTextTooltipProps) => {
    const tooLong = text ? text.length > maxLength : null;
    const shouldShowTooltip = tooLong || !!altText;
    const displayedText = tooLong ? text?.slice(0, maxLength) + '...' : text;
    const tooltipStyle = {
        fontSize: '0.9rem',
    };
    const fullText = altText || text;
    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(() => ({[`& .${tooltipClasses.tooltip}`]: tooltipStyle}));

    return (
        <CustomTooltip title={shouldShowTooltip && fullText ? fullText : ''} arrow placement={placement as TooltipProps["placement"]}>
            <Typography className={styles.typography} variant="inherit">{displayedText || children}</Typography>
        </CustomTooltip>
    );
}
