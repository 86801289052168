import {useSearchParams} from "react-router-dom";

const useURLParams = (paramName: string | null = null) => {
    let [searchParams, setSearchParams] = useSearchParams();

    const defaultValues: Record<string, any> = {
        manager_id: "",
        assigned_to: "",
        follower_id: "",
        unitFilterId: "",
        "page[limit]": 10,
    };

    /**
     * Updates a URL search parameter with the provided value.
     *
     * @param paramValue - The value to set for the URL parameter. If empty, the parameter is removed.
     *
     * @remarks
     * - If `paramName` is an empty string, the function will return without making any changes.
     * - Uses the `setSearchParams` function to update the URL.
     */
    const setURLParam = (paramValue: string) => {
        if (!paramName) return;
        setSearchParams((prevParams: URLSearchParams) => {
            prevParams.set(paramName, paramValue);
            if (!paramValue) prevParams.delete(paramName);
            return prevParams;
        });
    };

    /**
     * Retrieves all URL search parameters that belong to a specific category.
     * A category is identified by the prefix in the parameter name, such as:
     * "filter[manager_id]", "filter[assigned_to]", "filter[follower_id]", filter[unit_id]
     * It then extracts the parameter keys, removes the category prefix and square brackets,
     * and returns an object of key-value pairs:
     *
     * @param category - The category prefix to filter parameters by (e.g., 'filter').
     * @returns An object containing key-value pairs where keys are the URL parameter names
     *          (after the category and brackets have been removed) and values are the corresponding parameter values.
     *          e.g. {manager_id: 1, assigned_to: 2, follower_id: 3, unit_id: 4}
     */
    const getParamsByCategory = (category: string) => {
        // Convert searchParams.entries() to an array
        const paramsArray = Array.from(searchParams.entries());

        // Filter and transform the parameters that match the given category
        const result = Object.fromEntries(
            paramsArray
                .filter(([key]) => key.startsWith(category))
                .map(([key, value]) => {
                    const param = key.replace(`${category}[`, "").replace("]", "");
                    const newValue = value || defaultValues[key];
                    return [param, newValue];
                })
        );
        return result;
    };

    /**
     * Adds or updates a value in a comma-separated list within a URL parameter.
     *
     * @param sortItem - The sort item to add or update in the URL.
     * e.g. `{ creation_date: 'asc'}`
     */
    const addSortingParam = (sortItem: {[key: string]: "desc" | "asc"}) => {
        if (!paramName) return;
        const currentParamValues = searchParams.get(paramName)?.split(",") ?? [];
        const itemValue = _transformParamValue(sortItem);
        const [itemName] = Object.keys(sortItem);
        const paramIndex = currentParamValues.findIndex((param) => param.replace("-", "") === itemName);

        if (paramIndex !== -1) {
            currentParamValues[paramIndex] = itemValue;
        } else {
            currentParamValues.push(itemValue);
        }

        const newParamValue = currentParamValues.join(",") || "";

        setURLParam(newParamValue);
    };

    /**
     * Removes a value from a comma-separated list within a URL parameter.
     * e.g. `sort=-status,unit_code`
     *
     * @param sortItemName - The sort item name that should be removed.
     */
    const removeSortingParam = (sortItemName: string) => {
        if (!paramName) return;
        const currentParamValues = searchParams.get(paramName);
        const newParamValue =
            currentParamValues
                ?.split(",")
                .filter((param) => param.replace("-", "") !== sortItemName)
                .join(",") ?? "";
        setURLParam(newParamValue);
    };

    return {
        searchParams,
        setURLParam,
        getParamsByCategory,
        addSortingParam,
        removeSortingParam,
    };
};

/**
 * Transforms a `{ [key: string]: 'desc' | 'asc' }` object into a string representation.
 *
 * Example:
 * Given `sortItem` is `{ creation_date: 'asc', status: 'desc' }`, it will return `'creation_date,-status'`.
 *
 * @param sortItem - `{creation_date: 'asc', status: 'desc'}`
 * @returns A comma-separated string of properties with optional '-' prefix for descending order.
 *          e.g. `'-status,creation_date,-unit_code'`
 */
export const _transformParamValue = (sortItem: {[key: string]: "desc" | "asc"} | {}): string => {
    return Object.entries(sortItem)
        .map(([property, order]) => {
            const prefix = order === "desc" ? "-" : "";
            return `${prefix}${property}`;
        })
        .join(",");
};

export default useURLParams;
