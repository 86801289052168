import React, { useState, useEffect } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { truncateFilename } from "utils/helpers";
import { fetchAttachments } from "store/ticketEditAttachmentsSlice";
import { ReactComponent as IconTrash } from "assets/icons/trash.svg"
import { TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import { DeleteAttachmentModal } from "components/ticket-edit-attachments-delete-modal/DeleteAttachmentModal";
import { UploadAttachmentModal } from "components/ticket-edit-attachments-upload-modal/UploadAttachmentModal";
import { RootState } from "store/store";
import styles from './TicketEditAttachment.module.scss'
import { useModalScroll } from "hooks/useModalScroll";

interface ITicketEditAttachmentsProps {
    ticketId: number;
    isEditable: boolean;
}

const MAX_FILENAME_LENGTH = 28;

export const TicketEditAttachments: React.FC<ITicketEditAttachmentsProps> = ({ ticketId, isEditable }) => {

    const attachments = useSelector((state: RootState) => state.ticketEditAttachments.attachments);
    const [showUploadAttachmentModal, setShowUploadAttachmentModal] = useModalScroll(false);
    const [showRemoveAttachmentModal, setShowRemoveAttachmentModal] = useModalScroll(false);
    const [selectedAttachment, setSelectedAttachment] = useState({});
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();;

    useEffect(() => {
        const getTicketAttachments = (id: number | undefined) => {
            if (!id) return
            dispatch(fetchAttachments(id));
        }

        getTicketAttachments(ticketId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenRemoveModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { filename } = event.currentTarget.dataset;
        setSelectedAttachment({ticketId, filename});
        setShowRemoveAttachmentModal(true);
    };

    const Header = (
        <div>
            <h2>Attachments</h2>
            <button
                onClick={() => setShowUploadAttachmentModal(true)}
                className="btn-link"
                disabled={!isEditable}
            >
                Attach File
            </button>
        </div>
    );

    return (
        <TicketBoxCollapsable header={Header}>
            <div>
                {
                    attachments.length ? attachments.map((attachment: any, index: number) => (
                        <div key={index} className={styles.ticketAttachmentsListElement} >
                            <a
                                href={attachment.attributes.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {truncateFilename(attachment.attributes.filename, MAX_FILENAME_LENGTH)}
                            </a>
                            <button
                                className="btn-chip"
                                onClick={handleOpenRemoveModal}
                                data-filename={attachment.attributes.filename}
                            >
                                <IconTrash className="icon-bg" />
                            </button>
                        </div>
                    )) : <p>Not found</p>
                }
            </div>
            {showRemoveAttachmentModal && <DeleteAttachmentModal
                setShowModal={setShowRemoveAttachmentModal}
                selectedAttachment={selectedAttachment}
            />}
            {showUploadAttachmentModal && <UploadAttachmentModal
                setShowModal={setShowUploadAttachmentModal}
                ticketId={ticketId}
            />}
        </TicketBoxCollapsable>
    );
}
