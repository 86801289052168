import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EVENT_LOG_DATA} from "appConstants";
import {ConnectService} from "services/ConnectService";
import {TicketingService} from "services/TicketingService";
import {TicketV1Attributes, UserConnectAttributes} from "typing/dto";
import {GetResponse} from "typing/request";
import {enqueueError, enqueueSuccess, getErrorMessage} from "utils/message";

const {TICKET_REASSIGN_ADD} = EVENT_LOG_DATA;

interface reassignThunkType {
    ticketId: number;
    assignTo: number;
}

// Service Singletons
const ticketingService = TicketingService.getInstance();
const connectService = ConnectService.getInstance();

/**
 * Fetch the current user assigned to the ticket.F
 */
export const fetchAssignedTo = createAsyncThunk("ticketEditAssignment/fetchAssignedTo", async (id: number) => {
    return connectService.getUserById(id);
});

/**
 * Re-assign ticket based on user selected.
 */
export const reassign = createAsyncThunk<any, reassignThunkType>(
    "ticketEditAssignment/reassign",
    async ({ticketId, assignTo}, {rejectWithValue}): Promise<TicketV1Attributes> => {
        try {
            return ticketingService.patchTicket(ticketId, {assigned_to: assignTo});
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export interface ITicketEditAssignmentState {
    isLoadingFetch: boolean;
    isLoadingReassign: boolean;
    assignedTo: null | UserConnectAttributes;
}

export const initialState: ITicketEditAssignmentState = {
    isLoadingFetch: false,
    isLoadingReassign: false,
    assignedTo: null,
};

const ticketEditAssignmentSlice = createSlice({
    name: "ticketEditAssignment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssignedTo.pending, (state: ITicketEditAssignmentState) => {
                state.isLoadingFetch = true;
                state.assignedTo = null;
            })
            .addCase(
                fetchAssignedTo.fulfilled,
                (state: ITicketEditAssignmentState, {payload}: {payload: GetResponse<UserConnectAttributes> | null}) => {
                    state.isLoadingFetch = false;
                    state.assignedTo = payload?.data.attributes as UserConnectAttributes;
                }
            )
            .addCase(fetchAssignedTo.rejected, (state: ITicketEditAssignmentState) => {
                state.isLoadingFetch = false;
            })
            .addCase(reassign.pending, (state: ITicketEditAssignmentState) => {
                state.isLoadingReassign = true;
            })
            .addCase(reassign.fulfilled, (state: ITicketEditAssignmentState, {payload}: {payload: TicketV1Attributes}) => {
                state.isLoadingReassign = false;
                const {assigned_to} = payload;
                enqueueSuccess({logInfo: TICKET_REASSIGN_ADD, data: {assigned_to}});
            })
            .addCase(reassign.rejected, (state: ITicketEditAssignmentState, {payload}: {payload: any}) => {
                state.isLoadingReassign = false;
                enqueueError({logInfo: TICKET_REASSIGN_ADD, error: Error(payload)});
            });
    },
});

export default ticketEditAssignmentSlice.reducer;
