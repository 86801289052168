import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {TicketingService} from "services/TicketingService";
import {DispositionV1Attributes} from "typing/dto";
import {DataType} from "typing/request";

// Service Singletons
const ticketingService = TicketingService.getInstance();

export interface fetchInfoPayload {
    dispositionThirdLevel: DataType<DispositionV1Attributes>;
    dispositionSecondLevel: DataType<DispositionV1Attributes>;
    dispositionFirstLevel: DataType<DispositionV1Attributes>;
}

export const fetchInfo = createAsyncThunk("ticketEditInfo/fetchInfo", async (dispositionId: number) => {
    // Build Disposition Tree
    const dispositions = await ticketingService.getDispositions();
    let dispositionThirdLevel = dispositions.find((disposition) => Number(disposition.id) === dispositionId);
    const dispositionSecondLevelId = dispositionThirdLevel?.attributes.parent_id || 0;
    let dispositionSecondLevel = dispositions.find((disposition) => Number(disposition.id) === dispositionSecondLevelId);
    const dispositionFirstLevelId = dispositionSecondLevel?.attributes.parent_id || 0;
    let dispositionFirstLevel = dispositions.find((disposition) => Number(disposition.id) === dispositionFirstLevelId);

    // reorganize dispositions if missing tier
    if (!dispositionFirstLevel && dispositionSecondLevel && dispositionThirdLevel) {
        dispositionFirstLevel = dispositionSecondLevel;
        dispositionSecondLevel = dispositionThirdLevel; // @ts-ignore
        dispositionThirdLevel = null;
    }

    return {
        dispositionThirdLevel,
        dispositionSecondLevel,
        dispositionFirstLevel,
    };
});

export interface ITicketEditInfoState {
    isLoadingFetch: boolean;
    dispositionThirdLevel: DataType<DispositionV1Attributes> | null;
    dispositionSecondLevel: DataType<DispositionV1Attributes> | null;
    dispositionFirstLevel: DataType<DispositionV1Attributes> | null;
}

export const initialState: ITicketEditInfoState = {
    dispositionThirdLevel: null,
    dispositionSecondLevel: null,
    dispositionFirstLevel: null,
    isLoadingFetch: true,
};

const ticketEditInfoSlice = createSlice({
    name: "ticketEditInfo",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInfo.pending, (state: ITicketEditInfoState) => {
                state.isLoadingFetch = true;
                state.dispositionThirdLevel = null;
            })
            // @ts-ignore
            .addCase(fetchInfo.fulfilled, (state: ITicketEditInfoState, {payload}: {payload: fetchInfoPayload}) => {
                state.isLoadingFetch = false;
                state.dispositionThirdLevel = payload.dispositionThirdLevel;
                state.dispositionSecondLevel = payload.dispositionSecondLevel;
                state.dispositionFirstLevel = payload.dispositionFirstLevel;
            });
    },
});

export default ticketEditInfoSlice.reducer;
