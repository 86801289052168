import React, { useEffect, useState } from "react";
import { ReminderRemoveModal } from "components/ticket-edit-reminders-remove-modal/ReminderRemoveModal";
import { ReminderAddModal } from "components/ticket-edit-reminders-add-modal/ReminderAddModal";
import { TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import { ReactComponent as IconTrash } from "../../assets/icons/trash.svg"
import { fetchReminders } from "store/ticketEditRemindersSlice";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "components/spinner/Spinner";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { useModalScroll } from "hooks/useModalScroll";
import { ConnectService } from "services/ConnectService";

interface TicketEditRemindersProps {
    ticketId: number;
    isEditable: boolean;
}

export const TicketEditReminders: React.FC<TicketEditRemindersProps> = ({ticketId, isEditable}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const reminders = useSelector((state: RootState) => state.ticketEditReminders.reminders);
    const isLoadingFetch = useSelector((state: RootState) => state.ticketEditReminders.isLoadingFetch);
    const currentUserId: number = ConnectService.getInstance().currentUserId;
    const [showAddModal, setShowAddModal] = useModalScroll(false);
    const [showRemoveModal, setShowRemoveModal] = useModalScroll(false);
    const [selectedReminder, setSelectedReminder] = useState({});

    useEffect(() => {
        dispatch(fetchReminders({userId: currentUserId, ticketId}));
    }, [dispatch, currentUserId, ticketId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleShowRemoveModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        const {message, date} = event.currentTarget.dataset;
        const id = Number(event.currentTarget.dataset.id)
        setSelectedReminder({id, message, date});
        setShowRemoveModal(true);
    }

    const TicketEditReminderHeader = ({ options = true }) => (
        <div>
            <h2>Reminders</h2>
            <button
                onClick={() => setShowAddModal(true)}
                className="btn-link"
                disabled={!isEditable || !options}
            >
                Add Reminder
            </button>
        </div>
    )

    // Display loader until
    if (isLoadingFetch) {
        return (
            <TicketBoxCollapsable
                header={<TicketEditReminderHeader options={false} />}>
                <Spinner/>
            </TicketBoxCollapsable>
        );
    }

    return (
        <TicketBoxCollapsable header={<TicketEditReminderHeader />} >
            {reminders ? reminders.map((reminder: any, index: number) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                    <div>
                        <b style={{ marginRight: "10px" }}>{reminder.display_date}</b>
                        <button
                            className="btn-chip"
                            data-id={reminder.id}
                            data-message={reminder.message}
                            data-date={reminder.display_date}
                            onClick={handleShowRemoveModal}
                            data-testid="remove-button"
                        >
                            <IconTrash className="icon-bg" />
                        </button>
                    </div>
                    <div>
                        {reminder.message}
                    </div>
                </div>
            )) : "No active reminders."}
            {showAddModal && <ReminderAddModal setShowModal={setShowAddModal} ticketId={ticketId} />}
            {showRemoveModal && <ReminderRemoveModal
                setShowModal={setShowRemoveModal}
                selectedReminder={selectedReminder}
            />}
        </TicketBoxCollapsable>
    );
}
