import React from "react";
import { TicketBox } from "components/ticket-box/TicketBox";
import { TicketEditSeverityModal } from "components/ticket-edit-severity-modal/TicketEditSeverityModal";
import { TicketEntity } from "entities/ticket";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useModalScroll } from "hooks/useModalScroll";


interface ITicketEditSeverityProps {
    ticketId: number;
    isEditable: boolean;
}

export const TicketEditSeverity: React.FC<ITicketEditSeverityProps> = ({ticketId, isEditable}) => {
    const severityId = useSelector((state: RootState) => (state.ticketEdit?.ticket?.severity || 1));
    const [showModal, setShowModal] = useModalScroll(false);

    const getCurrentSeverity = (): string => {
        const currentSeverity = TicketEntity.severityOptions.find((severity: {name: string, id: number}) => severity.id === severityId);
        return currentSeverity ? currentSeverity.name : '-';
    }

    return ( 
        <TicketBox>
            <div className="box-link">
                <button onClick={() => setShowModal(true)} className="btn-link" disabled={!isEditable}>Edit Severity</button>
            </div>
            <div className="box-title">Severity:</div>
            <div className="box-value">{getCurrentSeverity()}</div>
            {showModal && <TicketEditSeverityModal ticketId={ticketId} setShowModal={setShowModal} currentSeverityId={severityId} />}
        </TicketBox>
    );
}
