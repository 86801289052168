import React, {useEffect} from "react";
import classNames from 'classnames';
import { TicketSearchHeader } from "components/ticket-search-header/TicketSearchHeader";
import styles from "./TicketSearch.module.scss";
import { TicketSearchFilters } from "components/ticket-search-filters/TicketSearchFilters";
import { TicketSearchTable } from "components/ticket-search-table/TicketSearchTable";
import { closeAlert, fetchUsers, fetchTickets, fetchUnitCodes } from "store/ticketSearchSlice";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "components/spinner/Spinner";
import { GetListResponse, PageQueryParams, SearchOrderByQueryParams, SearchSelectedFilterValues } from "typing/request";
import { TicketV1Attributes } from "typing/dto";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AlertMessage, AlertMessageParams } from "components/alert-message/AlertMessage";
import useURLParams from "hooks/useURLParams";
import { filtersToParams, orderByToParams } from "utils/list-params";

export const TicketSearch: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { searchParams, getParamsByCategory } = useURLParams();
    const tickets = useSelector((state: RootState) => state.ticketSearch.tickets);

    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );
    const orderBy: SearchOrderByQueryParams = useSelector((state: RootState) => state.ticketSearch.orderBy)
    const page: PageQueryParams = useSelector((state: RootState) => state.ticketSearch.page);
    const isLoading: boolean = useSelector((state: RootState) => state.ticketSearch.isLoading);
    const reload: boolean = useSelector((state: RootState) => state.ticketSearch.reload);
    const alertMessageParams: AlertMessageParams = useSelector((state: RootState) => state.ticketSearch.alertMessageParams);

    const fetchData = async (filterQueryParams: any = null, pageQueryParams: any = null, sortingQueryParams: any = null) => {
        const result = await dispatch(fetchTickets({
            page: {...pageQueryParams, ...page},
            filter: Object.keys(filterQueryParams).length ? filterQueryParams : filtersToParams(selectedFilterValues),
            sort: Object.keys(sortingQueryParams).length ? sortingQueryParams : orderByToParams(orderBy),
        }));

        const listResponse = result.payload as GetListResponse<TicketV1Attributes>;
        if (!listResponse?.data?.length) return;
        const assignedToList = listResponse.data.map(ticket => ticket.attributes.assigned_to);
        const unitIdList = listResponse.data.map(ticket => ticket.attributes.unit_id);
        await Promise.all([
            dispatch(fetchUsers(assignedToList.join(','))),
            dispatch(fetchUnitCodes(unitIdList.join(','))),
        ]);
    };

    useEffect(() => {
        // Extract filter parameters from the URL query
        const filterQueryParams = getParamsByCategory('filter');

        // Extract pagination parameters from the URL query
        const pageQueryParams = getParamsByCategory('page');

        // Extract sorting parameter from the URL query
        const sortingQueryParams = searchParams.get('sort') || {};

        // Fetch the data with the parameters obtained from the URL
        fetchData(filterQueryParams, pageQueryParams, sortingQueryParams);
    },[reload]); // eslint-disable-line react-hooks/exhaustive-deps

    const spinnerComponent = <Spinner data-testid="spinner"/>;

    // Display loader until
    if ((!tickets.length && !Object.keys(selectedFilterValues).length) && isLoading) {
        return (
          <div data-testid="spinner" className={styles.spinner} style={{opacity: 1}}>
            {spinnerComponent}
          </div>
        );
    }

    return (
        <div className={classNames(styles.container,"max-width")}>
            {isLoading && <div className={styles.spinner}>
                {spinnerComponent}
            </div>}
            <TicketSearchHeader/>
            <TicketSearchFilters/>
            <AlertMessage params={alertMessageParams} onClose={() => dispatch(closeAlert())}/>
            <TicketSearchTable/>
        </div>
    );
}
