import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EVENT_LOG_DATA} from "appConstants";
import {TicketingService} from "services/TicketingService";
import {enqueueError, enqueueSuccess, getErrorMessage} from "utils/message";

const {TICKET_ATTACHMENT_DELETE, TICKET_ATTACHMENT_ADD} = EVENT_LOG_DATA;

// Service Singletons
const ticketingService = TicketingService.getInstance();

type AttachmentItem = {
    type: string;
    attributes: {
        filename: string;
        url: string;
    };
};

export const fetchAttachments = createAsyncThunk("ticketEdit/fetchAttachments", async (id: number) => {
    const response = await ticketingService.getAttachments(id);
    return response.data;
});

export const addAttachments = createAsyncThunk("ticketEdit/addAttachments", async ({ticketId, formData}: any, {rejectWithValue}) => {
    try {
        await ticketingService.addAttachments(ticketId, formData);
        const response = await ticketingService.getAttachments(ticketId);
        return response.data;
    } catch (error) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const deleteAttachment = createAsyncThunk("ticketEdit/deleteAttachment", async ({ticketId, filename}: any, {rejectWithValue}) => {
    try {
        await ticketingService.deleteAttachment(ticketId, filename);
        return filename;
    } catch (error) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export interface ITicketEditAttachmentsState {
    isLoadingFetch: boolean;
    attachments: AttachmentItem[];
}

const ticketEditSlice = createSlice({
    name: "ticketEdit",
    initialState: {
        isLoadingFetch: false,
        attachments: [],
    } as ITicketEditAttachmentsState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttachments.fulfilled, (state: ITicketEditAttachmentsState, {payload}: {payload: any}) => {
                state.attachments = payload.map((attachment: AttachmentItem) => ({
                    ...attachment,
                    attributes: {
                        ...attachment.attributes,
                        filename: attachment.attributes.filename.split("/")[1],
                    },
                }));
            })
            .addCase(addAttachments.rejected, (_state: ITicketEditAttachmentsState, {payload}: {payload: any}) => {
                enqueueError({logInfo: TICKET_ATTACHMENT_ADD, error: Error(payload)});
            })
            .addCase(addAttachments.fulfilled, (state, {payload}: {payload: any}) => {
                state.attachments = payload.map((attachment: AttachmentItem) => ({
                    ...attachment,
                    attributes: {
                        ...attachment.attributes,
                        filename: attachment.attributes.filename.split("/")[1],
                    },
                }));
                enqueueSuccess({logInfo: TICKET_ATTACHMENT_ADD, data: state.attachments.map((a) => a.attributes.filename)});
            })
            .addCase(deleteAttachment.rejected, (_state: ITicketEditAttachmentsState, {payload}: {payload: any}) => {
                enqueueError({logInfo: TICKET_ATTACHMENT_DELETE, error: Error(payload)});
            })
            .addCase(deleteAttachment.fulfilled, (state: ITicketEditAttachmentsState, {payload}: {payload: string}) => {
                state.attachments = state.attachments.filter((attachment: AttachmentItem) => attachment.attributes.filename !== payload);
                enqueueSuccess({logInfo: TICKET_ATTACHMENT_DELETE, data: {payload}});
            });
    },
});

export default ticketEditSlice.reducer;
