import React, {useState} from "react";
import styles from "./TicketBoxCollapsable.module.scss";
import { ReactComponent as IconArrow } from "../../assets/icons/arrow.svg"
import classnames from "classnames";

interface TicketBoxCollapsableProps extends React.HTMLProps<HTMLDivElement> {
    id?: string;
    header?: React.ReactNode
    children: React.ReactNode
}

export const TicketBoxCollapsable: React.FC<TicketBoxCollapsableProps> = ({id = "", header, children, ...divProps}) => {
    const [toggled, setToggled] = useState(false);
    
    return ( 
        <div id={id} className={classnames(styles.box, toggled ? styles.toggled: "")}>
            <div className={styles.header}>
                {header}
                <IconArrow data-testid="ticket-box-collapsable-icon" onClick={() =>setToggled(!toggled)}/>
            </div>
            
            <div className={styles.content} {...divProps}>
                {children}
            </div>
        </div>
    );
}
