import React, {useEffect, useState} from "react";
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import styles from "./SearchBar.module.scss";
import useCallbackWithDebounce from "hooks/useCallbackWithDebounce";

export interface ISearchBarProps<T> {
    fetchData: (search: string) => Promise<T[]>;
    onSelected: (value: T | null) => void;
    getOptionLabel: (option: T) => string;
    getOptionSelected: (option: T, value: T) => boolean;
    getOptionDisabled: (option: T) => boolean;
    disabled?: boolean;
    placeholder?: string;
}

export type ItemType = {
    id: number;
    display: string;
}

export const SearchBar: React.FC<ISearchBarProps<ItemType>> = ({
    fetchData,
    disabled,
    placeholder = "Enter 3 or more characters...",
    onSelected,
    getOptionLabel,
    getOptionDisabled,
    getOptionSelected
}) => {
    const [options, setOptions] = useState<ItemType[]>([]);
    const [selected, setSelected] = useState<ItemType | null>(null);
    const [internalSearch, setInternalSearch] = useState<string>("");
    const [searching, setSearching] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false)

    // Clear auto complete input options
    const clearOptions = () => {
        setOptions([]);
    }

    const search = useCallbackWithDebounce(async (searchString: string) => {
        if (searchString === null || searchString === selected?.display) return;
        if (searchString.length <= 2) {
            clearOptions();
            return;
        }
        setSearching(true);
        fetchData(searchString)
            .then((res: ItemType[]) => setOptions(res))
            .catch(() => setOptions([]))
            .finally(() => setSearching(false));
    });

    useEffect(() => {
        search(internalSearch);
    }, [search, internalSearch])

    useEffect(() => {
        if (!disabled) setInternalSearch("");
    }, [disabled])


    // handlers
    const handleSelectedChange = (_event: React.ChangeEvent<{}>, newValue: ItemType | null) => {
        setSelected(newValue);
        onSelected(newValue);
    }

    const handleInputChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
        setInternalSearch(newValue);
        clearOptions()
        handleOpen()
    };

    const handleOpen = () => {
        if (internalSearch === "") setOpen(false);
        else setOpen(true);
    };

    return (
        <span className={styles["search-bar-box"]}>
            <Autocomplete
                id="search-bar-combo-box"
                options={options}
                value={{display: internalSearch, id: 0}}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                getOptionDisabled={getOptionDisabled}
                onChange={handleSelectedChange}
                onInputChange={handleInputChange}
                open={open}
                disabled={disabled}
                loading={searching}
                onOpen={handleOpen}
                openOnFocus={false}
                onClose={() => setOpen(false)}
                renderInput={(params) => <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 100 }}
                    variant="outlined"
                    placeholder={placeholder}
                />}
            />
        </span>
    );
}
