import React, {useEffect, useState} from "react";
import styles from "./TicketSearchFilters.module.scss";
import { MultiSelectFilter } from "components/multi-select-filter/MultiSelectFilter";
import { RootState, useAppDispatch } from "store/store";
import { openErrorAlert, setReload, setSelectedFilterValues } from "store/ticketSearchSlice";
import { TicketingService } from "services/TicketingService";
import { DataType, FilterOption, SearchSelectedFilterValues } from "typing/request";
import { AssignableUserAttributes, UnitV2Attributes, UserConnectAttributes } from "typing/dto";
import { UnitService } from "services/UnitService";
import { useSelector } from "react-redux";
import { ConnectService } from "services/ConnectService";
import useURLParams from "hooks/useURLParams";

const connectService = ConnectService.getInstance();

interface TicketSearchFiltersProps {
}

export const TicketSearchFilters: React.FC<TicketSearchFiltersProps> = () => {
    const dispatch = useAppDispatch();
    const { getParamsByCategory } = useURLParams();
    const [managerOptions, setManagerOptions] = useState([] as FilterOption[]);
    const [assigneeOptions, setAssigneeOptions] = useState([] as FilterOption[]);
    const [followerOptions, setFollowerOptions] = useState([] as FilterOption[]);
    const [unitOptions, setUnitOptions] = useState([] as FilterOption[]);
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );

    const ticketingService = TicketingService.getInstance();
    const unitService = UnitService.getInstance();

    const managerFilterId = 'manager_id';
    const assigneeFilterId = 'assigned_to';
    const followerFilterId = 'follower_id';
    const unitFilterId = 'unit_id';

    const transformUserToFilterItem = (users: UserConnectAttributes[]) => {
        return users.map(({ first_name, last_name, id }) => ({
            name: `${first_name} ${last_name}`,
            id,
        }));
    }

    const transformUnitToFilterItem = (units: UnitV2Attributes[]) => {
        return units.map(({ unit_code, legacy_unit_id }) => ({
            name: unit_code,
            id: legacy_unit_id,
        }));
    }

    const getUserIdsFromQueryParams = (filterQueryParams: {[k: string]: string}) => {
        const userFilterIds = [assigneeFilterId, followerFilterId, managerFilterId];
        return Object.entries(filterQueryParams)
            .filter(([key]) => userFilterIds.includes(key))
            .map(([, value]) => value)
            .join(",");
    }

    const filterUsersById = (usersFilterItems: FilterOption[], filterValue: string) => {
        return usersFilterItems?.filter((user) => filterValue?.split(",").includes(String(user.id)))
    }

    const getSelectedUsers = async (): Promise<SearchSelectedFilterValues | {}> => {
        const filterQueryParams = getParamsByCategory('filter');
        const userIds = getUserIdsFromQueryParams(filterQueryParams);

        if (!userIds) return {}

        try {
            const usersResponse = await connectService.getUsersById(userIds);
            const usersFilterItems = transformUserToFilterItem(usersResponse);

            return {
                [assigneeFilterId]: filterUsersById(usersFilterItems, filterQueryParams[assigneeFilterId]),
                [followerFilterId]: filterUsersById(usersFilterItems, filterQueryParams[followerFilterId]),
                [managerFilterId]: filterUsersById(usersFilterItems, filterQueryParams[managerFilterId]),
            }
        }  catch (e) {
            return Promise.reject(`Error: ${JSON.stringify(e)}`);
        }
    }

    const getSelectedUnits = async (): Promise<{ unitId: FilterOption[] } | {}> => {
        // Get filter query params
        const filterQueryParams = getParamsByCategory('filter');

        // Return early if unit_id is not available
        if (!filterQueryParams[unitFilterId]) return {}

        try {
            // Fetch units by legacy ID
            const unitResponse = await unitService.getUnitsByLegacyId(filterQueryParams[unitFilterId]);
            return { [unitFilterId]: transformUnitToFilterItem(unitResponse) };
        } catch (e) {
            return Promise.reject(`Error: ${JSON.stringify(e)}`);
        }
    }

    useEffect(() => {
         /**
         * Initializes filter values from query parameters.
         * 
         * This function retrieves the selected users and units from query parameters
         * then dispatches an action to update the selected filter values.
         */
        const initializeFilterValues = async () => {
            const selectedUsers = await getSelectedUsers();
            const selectedUnits = await getSelectedUnits();
            dispatch(setSelectedFilterValues({ ...selectedUsers, ...selectedUnits }));
        }
        initializeFilterValues();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateList = async (selectedList: FilterOption[], filterId: string) => {
        // Crates a full string with the results 
        const selectedValues = selectedList.map(item => item.id).join(',');

        // Adds the filter by ID
        const paramFilter: { [key: string]: string } = {};
        paramFilter[filterId] = selectedValues;

        // Set filters selected values
        dispatch(setSelectedFilterValues({
            ...selectedFilterValues,
            [filterId]: selectedList
        }))

        // Reloads the page
        dispatch(setReload());
    };

    const errorHandler = (fetcherContext: string) => {
        dispatch(openErrorAlert(
            `Error on fetching ${fetcherContext.toUpperCase()}, please get in touch with your support team or try again.`
        ));
    }

    const fetchUnits = async (searchString: string): Promise<FilterOption[] | void> => {
        try {
            const units = await unitService.getUnitsByParams({
                filter: {
                    unit_code: {
                        startswith: searchString.toUpperCase()
                    }
                }
            });
            return transformUnitToFilterItem(units)
        } catch (e) {
            errorHandler('units');
            return Promise.reject(`Error: ${JSON.stringify(e)}`);
        }
    };

    const fetchAssignees = async (searchString: string, errorContext: string): Promise<FilterOption[] | void> => {
        try {
            const assignees = await ticketingService.getAssignees({
                search_term: searchString
            }, {});
            return assignees.data.map((user: DataType<AssignableUserAttributes>) => {
                const { attributes, id } = user;
                const { first_name, last_name } = attributes;
                return {
                    "name": `${first_name} ${last_name}`,
                    "id": id
                }
            });
        } catch (e) {
            errorHandler(errorContext);
            return Promise.reject(`Error: ${JSON.stringify(e)}`);

        }
    };

    return (
        <div className={styles.content}>
            <div className={styles.item}>
                <MultiSelectFilter
                    title="MANAGER"
                    id={managerFilterId}
                    options={managerOptions}
                    onFetchData={(searchString: string) => fetchAssignees(searchString, "managers")}
                    onUpdate={updateList}
                    setOptions={setManagerOptions}
                    selectedValues={selectedFilterValues[managerFilterId]}
                />
            </div>
            <div className={styles.item}>
                <MultiSelectFilter
                    title="ASSIGNEE"
                    id={assigneeFilterId}
                    options={assigneeOptions}
                    onFetchData={(searchString: string) => fetchAssignees(searchString, "assignees")}
                    onUpdate={updateList}
                    setOptions={setAssigneeOptions}
                    selectedValues={selectedFilterValues[assigneeFilterId]}
                />
            </div>
            <div className={styles.item}>
                <MultiSelectFilter
                    title="FOLLOWER"
                    id={followerFilterId}
                    options={followerOptions}
                    onFetchData={(searchString: string) => fetchAssignees(searchString, "followers")}
                    onUpdate={updateList}
                    setOptions={setFollowerOptions}
                    selectedValues={selectedFilterValues[followerFilterId]}
                />
            </div>
            <div className={styles.item}>
                <MultiSelectFilter
                    title="UNIT"
                    id={unitFilterId}
                    options={unitOptions}
                    onFetchData={fetchUnits}
                    onUpdate={updateList}
                    setOptions={setUnitOptions}
                    selectedValues={selectedFilterValues[unitFilterId]}
                />
            </div>
        </div>
    );
}
