import {datadogLogs} from "@datadog/browser-logs";
import {env, EnvMapper} from "core/env";
import tags from "core/tags";
import moment from "moment-timezone";
import {ImplicitFlowConfigType} from "typing";

export class Configuration {
    static innerRootId = "ticketing-root";

    public static appUrl(): string {
        const {REACT_APP_SPA_URL} = env;
        return REACT_APP_SPA_URL || "";
    }

    /**
     * Datadog Web Agent Url
     */
    public static dataDogUrl(): string {
        const {REACT_APP_DD_URL} = env;
        return REACT_APP_DD_URL || "";
    }

    /**
     * Retrieves Implicit Flow configuration
     */
    public static implicitFlowConfig(): ImplicitFlowConfigType {
        const {REACT_APP_IDP_CLIENT_ID, REACT_APP_SPA_IDP_AUDIENCE, REACT_APP_SPA_IDP_SCOPES, REACT_APP_IDP_REDIRECT_URI} = env;

        let environment = "stage";
        switch (EnvMapper.environment()) {
            case "staging":
                environment = "stage";
                break;
            case "production":
                environment = "prod";
                break;
            default:
                environment = "stage";
                break;
        }

        return {
            clientId: REACT_APP_IDP_CLIENT_ID,
            audience: REACT_APP_SPA_IDP_AUDIENCE,
            scopes: REACT_APP_SPA_IDP_SCOPES,
            directoryHint: "onelogin",
            env: environment as any,
            redirectUrl: REACT_APP_IDP_REDIRECT_URI,
            tokenRefreshRate: 1000 * 60 * 2, // 2 min refresh, this is a patch
        };
    }

    /**
     * Initialize Datadog and set Global Context
     */
    public static datadogInitAndContext(datadogLogger: typeof datadogLogs) {
        datadogLogger.init({
            clientToken: Configuration.getDataDogToken(),
            site: Configuration.dataDogUrl(),
            forwardErrorsToLogs: true,
            env: EnvMapper.shortEnvironment(),
            beforeSend: (log) => {
                // @ts-ignore
                if (log?.config?.headers) log.config.headers.Authorization = null;
                return true;
            },
        });

        datadogLogger.setGlobalContext({
            environment: EnvMapper.environment(),
            env: EnvMapper.shortEnvironment(),
            host: this.appUrl(),
            division: tags.division,
            project: tags.project,
            repository: tags.repository,
            team: tags.team,
            service: tags.service,
        });
    }

    /*
     * Gets Sentry DNS to senf exception tracing
     */
    public static getSentryDsn() {
        const {REACT_APP_SENTRY_DSN} = env;
        return REACT_APP_SENTRY_DSN;
    }

    /**
     * Get datadogLogs integration token
     */
    public static getDataDogToken(): string {
        const {REACT_APP_DATADOG_TOKEN} = env;
        return REACT_APP_DATADOG_TOKEN || "";
    }

    /**
     * Get default timezone
     */
    public static getTimeZone(): string {
        return "America/Los_Angeles";
    }
}

if (!EnvMapper.isLocal()) Configuration.datadogInitAndContext(datadogLogs);

// Use PST as Default Timezone
moment.tz.setDefault(Configuration.getTimeZone());
